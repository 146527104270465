import styled from 'styled-components';
import deviceView from 'core/constants/deviceView';

const StyleComponent = styled.div`
width: 100%;

.se-react-select-cn  {

    .se-react-select__control--is-disabled {
        background: var(--clearFilterColor) !important;
        .se-react-select__indicator{
            svg {
                path {
                    :last-child {
                        fill: #9FA4AB;
                    }
                }
            }
        }

        .se-react-select-multi-value-cn{
            color: var(--arrowIcon);
        }
    }

    .se-react-select__control {
        width: 100%;
        height: 32px;
        min-height: 32px;
        border-radius: 2px;
        box-shadow: none;
        background-color:var(--white);
        border-color: var(--borderColor);
        padding: 0 9px 0 11px;
        cursor: pointer;

        :hover {
            border-color: var(--border) !important;
        }

        .se-react-select__placeholder {
            color: var(--arrowIcon);
        }

        .se-react-select__clear-indicator {
            display: none;
        }
        @media ${deviceView.mobile}{
            height: 40px;
        }
    }

    .se-react-select__control--is-focused {
        .se-react-select__placeholder {
            color: var(--secondaryColor);
        }

        .se-react-select__clear-indicator {
            display: flex;
            align-items: center;
        }
    }

    .se-react-select__value-container {
        font-family: Regular;
        font-size: 13px;
        color:var(--secondaryColor);
        flex-wrap: nowrap;
        padding: 2px 0;
    }

    .se-react-select__input {
        margin-left: -2px;
    }

    .se-react-select__single-value {
        color: inherit;
        margin: 0;
    }

    .se-react-select__option {
        display: flex;
        align-items: center;
        font-family: Regular;
        font-size: 13px;
        padding: 8px 8px 8px 12px;
        color: var(--secondaryColor);
        cursor: pointer;
    }

    .se-react-select__option--is-focused {
        background-color: var(--backgroundColor);
    }

    .se-react-select__option--is-selected {
        background-color:var(--reactOnOptionSelect);
    }

    .se-react-select-option-label, .se-react-select-multi-value-cn {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .se-react-select-multi-value-cn {
        position: absolute;
        max-width: 100%;
    }

    .se-react-select-option-label {
        flex: 1;
    }

    .se-react-select-tick-icon {
        margin-left: 6px;
        padding: 0 2px;
    }
    .se-react-select-additional-info{
        font-size:11px !important;
    }
    .se-react-select-option-checkbox {
        display: flex;
        align-items: center;
        margin-right: 8px;
    }
    .se-react-select__menu-list {
        max-height: 200px;
        scroll-behavior: smooth;
        ::-webkit-scrollbar {
            width: 6px;
        };
        ::-webkit-scrollbar-thumb {
            background-color: var(--reactSelectScrollBar);
            border-radius: 6px;
        };
    }

    .se-react-select__indicator-separator {
        display: none;
    }

    .se-react-select__dropdown-indicator, .se-react-select__clear-indicator {
        padding: 0 0 0 8px;
    }

    .se-react-select-dropdown-indicator {
        display: flex;
    }

    .se-react-select-dropdown-indicator-rotate {
        transform: rotate(180deg);
        display: flex;
        align-items: center;
    }

    .se-react-select__multi-value {
        display: none;
    }

    .se-lazy-loading-cn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
    }

    .se-load-more-options-btn {
        border: 0;
        color: var(--searchBoxTextColor);
        font-size: 13px;
    }

    .se-spinner-cn {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            padding-left: 5px;
            font-size: 13px;
            color: var(--searchBoxTextColor);
        }
    }

}
.se-react-select__group{
    padding:0;
    .se-react-select__group-heading{
        padding:0;
        margin: 0;
        .se-react-select-group-separator{
            width:100%;
            height:1px;
            border-top:1px dashed var(--paginationBorder);
        }
        .se-react-select-group-label{
            white-space: nowrap;
        }
        .se-react-select-group-container{
        
        display:flex;
        align-items:center;
        }
    }
}

.se-react-select__menu-portal {
    z-index: 4;
}
.se-remove-red-border {
    .se-react-select__control {
        border-color: #D1331B !important;
    }
}
.se-add-green-border {
    .se-react-select__control {
        border-color: #3BB345 !important;
    }
}

.v1Reports {
    .se-react-select__menu-portal{
        .se-react-select__menu{
            margin-top: 0px;
        }

        .se-react-select__menu-list{
            border:1px solid var(--lsqBorder);
            border-radius: 2px;
        }

        .se-react-select__option{
        height: 24px;
        }

        .se-react-select__option--is-focused {
            background-color:var(--lsqHover) !important;
            color: var(--white);
        }
    
        .se-react-select__option--is-selected {
            background-color: var(--lsqSelected) !important;
            color: var(--white);
        }

        .rs-option-label-cn{
            font-family: Regular;
            font-size: 14px;
            text-align: left;
        }

        .se-react-select__menu-list {
            padding: 0px;
        }
    }

    .se-react-select__dropdown-indicator{
        padding: 0px;
    }

    .se-react-select__control {
        :hover {
            border-color : var(--lsqText2) !important;
        }
    }

    .se-react-select__option--is-selected{
        /* Hide tick icon for v1 reports  */
        span:nth-child(2){
            display: none;
        }
    }

    .se-react-select__single-value {
        bottom: -12px;
    }

}

`;
export default StyleComponent;
