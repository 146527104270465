/**
 * @todo: Add tablet and desktop once breakpoints are decided
 */
export enum size {
  sm = 430,
  mm = 431,
}

const device = {
  mobile: `(max-width: ${size.sm}px)`, // it will be applied to the mobile screen which has width of 430px or less
  desktop: `(min-width: ${size.mm}px)`, // it will be applied to the table/desktop which has width of more than 430px
};

export default device;
