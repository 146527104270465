import { ISelectFilterOptions } from 'core/models/filter';
/* eslint-disable no-shadow */
export enum BooleanFormatters{
    YesNo='YN',
    TrueFalse='TF',
    OnOff='OO',
    EnabledDisabled='ED',
    OneZero='OZ',
    CustomDefined='CD',
    Yes='Yes',
    No='No',
    True='True',
    False='False',
    Enabled='Enabled',
    Disabled='Disabled',
    On='On',
    Off='Off'
  }

export enum StringFormatters{
    LowerCase='LowerCase',
    UpperCase='UpperCase',
    CapitalCase='CapitalCase'
}

export enum TimeFormatters{
  HMM='hmm',
  MMSS='mmss',
  HMMSS='hmmss',
  HMMNoSuffix='hmm_nosuffix',
  HMMSSNoSuffix='hmmss_nosuffix',
  MMSSNoSuffix='mmss_nosuffix',
  HHMMSS='hh:mm:ss',
  HHMMA='hh:mm A'
}
export enum DateTimeFormatters{
  DDMMYYYHHMMSS= 'dd/mm/yyyy hh:mm:ss'
}
export enum DateFormatters{
  WEEK='W',
  DAY='DoW',
  MONTH='Month',
  MDY='MMMDDYYYY',
  YMDHMS='YYYYMMDD hh:mm:ss',
  DDMMYYYY='DDMMYYYY',
  DDMMYY='DDMMYY',
  MMDDYY='MMDDYY',
  MMDDYYYY='MMDDYYYY',
  HHMMAMMMDDYYYY='hh:mmA, MMM DD, YYYY'
}

export enum Formatters{
  BOOLEAN='Boolean',
  TIME='Time',
  NUMBER='Number',
  CURRENCY='Currency',
  DATE='Date',
  STRING='String',
  DURATION='Duration',
  INT_NUMBER='INTNumber',
}

export enum DurationFormatter{
  MMSS='mmss',
  HHMM='hhmm',
  DHH='dhh',
  DAYS='DAYS',
  HMMSS='hmmss',
  MMSSNoSuffix='mmss_nosuffix',
  HMMSSNoSuffix='hmmss_nosuffix',
  HHMMNoSuffix='hhmm_nosuffix',
  SS='ss'
}

export enum DateBinningFormatters {
  NONE='NoneExpr',
  HOUROFTHEDAY='ToHourExpr',
  DATE_YEAR='ToDateOfYearExpr',
  DATE='ToDateExpr',
  MONTHOFTHEYEAR='ToMonthOfYearExpr',
  MONTH='ToMonthExpr',
  YEAR='ToYearExpr',
  QUARTER='ToQuarterExpr',
  QUARTEROFTHEYEAR='ToQuarterOfYearExpr',
  WEEKOFTHEYEAR='ToYearWeekExpr',
  WEEK='ToWeekExpr',
  DAY='ToDayOfWeekExpr'
}

export const daysOfWeek:any = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

export const months:any = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export enum FormatterMetaData {
  TimeStyle= 'TimeStyle',
  Seperator= 'Seperator',
  Decimal= 'Decimal',
  DateStyle= 'DateStyle',
  Symbol= 'Symbol',
  DurationStyle= 'DurationStyle',
}
export enum DataType {
  dropdown= 'Dropdown',
  text= 'Text',
}
export interface IFormatOption{
  id: any,
  label:string,
  type: DataType,
  list: ISelectFilterOptions[],
}
