import { ColumnDataTypes, FieldEntitiesType } from 'core/constants/report';
import {
  AggExprTypes,
  ArithmeticExprTypes,
  ArrayFnExprTypes,
  CompExprTypes,
  ConditionExprTypes,
  ConfigExprNamespace,
  DateDiffFnExprTypes,
  DateExprTypes,
  DateFnExprTypes,
  ExpressionSubTypes,
  ExpressionTypes,
  KeywordExprTypes,
  LogicalExprTypes,
  TenantSettingKey,
  TimeDuration, usersPrefix,
} from 'core/constants/report-expressions';
import { DateBinningFormatters } from 'core/constants/formatters';

/**
 * Tree-like representation of an expression. Expressions have a type T and may
 * have a subtype S if T has any. `Part` holds details of the expression (like
 * column name, data type, etc.) and its type is derived from T and S (see
 * `IExpressionPart`).
 *
 * Example usage:
 * ```
 * newSumExpr(
 *   newColumnExpr('Activity Revenue', ColumnDataTypes.IntType),
 *   newEqualExpr(
 *     newColumnExpr('Activity', ColumnDataTypes.Int64Type),
 *     newConstantExpr(123, ColumnDataTypes.IntType),
 *   ),
 * ),
 * ```
 */

export interface IExpression<
  T extends ExpressionTypes = ExpressionTypes,
  S extends ExpressionSubTypes<T> = ExpressionSubTypes<T>
> {
  Type: T;
  SubType: S;
  Part: IExpressionPart<T, S>;
}

/**
 * This type resolves the given expression type and subtype to a part interface.
 *
 * This makes it easy to know which part is for which type, and as a bonus
 * which subtype belongs to which type.
 * For instance, `IExpressionPart<Aggregation, Sum>` resolves to `ISumExprPart`
 * and `IExpressionPart<Aggregation, LessThan>` is a compile-time error.
 * Every subtype and interface have one to one relation
 */
export type IExpressionPart<
  T extends ExpressionTypes,
  S extends ExpressionSubTypes<T>
> = T extends ExpressionTypes.Condition
  ? IConditionalExprPart
  : T extends ExpressionTypes.Column
  ? IColumnExprPart
  : T extends ExpressionTypes.Constant
  ? IConstantExprPart
  : T extends ExpressionTypes.Keyword ? IKeywordExprPart
  : T extends ExpressionTypes.Config ? IConfigExprPart
  : // AggExpr
  S extends AggExprTypes.Sum
  ? ISumExprPart
  : S extends AggExprTypes.SumIf
  ? ISumIfExprPart
  : S extends AggExprTypes.Max
  ? IMaxExprPart
  : S extends AggExprTypes.MaxIf
  ? IMaxIfExprPart
  : S extends AggExprTypes.Min
  ? IMinExprPart
  : S extends AggExprTypes.MinIf
  ? IMinIfExprPart
  : S extends AggExprTypes.Avg
  ? IAvgExprPart
  : S extends AggExprTypes.AvgIf
  ? IAvgIfExprPart
  : S extends AggExprTypes.Count
  ? ICountExprPart
  : S extends AggExprTypes.CountIf
  ? ICountIfExprPart
  : S extends AggExprTypes.CountDistinct
  ? ICountDistinctExprPart
  : S extends AggExprTypes.CountDistinctIf
  ? ICountDistinctIfExprPart
  : S extends ArithmeticExprTypes.Plus ? IPlusExprPart
  : S extends ArithmeticExprTypes.Minus ? IMinusExprPart
  : S extends ArithmeticExprTypes.Multiply ? IMultiplyExprPart
  : S extends ArithmeticExprTypes.Divide ? IDivideExprPart
  : S extends CompExprTypes ? IComparisonExprPart<S>
  : S extends LogicalExprTypes.And ? IAndExprPart
  : S extends LogicalExprTypes.Or ? IOrExprPart
  : S extends LogicalExprTypes.Not ? INotExprPart
  : S extends LogicalExprTypes.IsNull ? IIsNullExprPart
  : S extends DateBinningFormatters ? IDateConvertExprPart
  : S extends DateFnExprTypes ? IDateFnExprPart
  : S extends DateDiffFnExprTypes ? IDateDiffFnExprPart
  : S extends ArrayFnExprTypes.ArrayJoinExpr ? IArrayJoinExprPart
  : never;

export interface IConditionalExprPart {
  Condition: IExpression;
  ThenPart: IExpression;
  ElsePart: IExpression;
}

// #region Helper Types
interface IConditionPartBase {
  Condition: IExpression;
}
type IAggExpr<S extends ExpressionSubTypes<ExpressionTypes.Aggregation>> =
  IExpression<ExpressionTypes.Aggregation, S>;
type IArithmeticExpr<T extends ExpressionSubTypes<ExpressionTypes.Arithmetic> = ArithmeticExprTypes> =
  IExpression<ExpressionTypes.Arithmetic, T>;
type ICompExpr<S extends ExpressionSubTypes<ExpressionTypes.Comparison>> =
  IExpression<ExpressionTypes.Comparison, S>;
type ILogicalExpr<S extends LogicalExprTypes> = IExpression<ExpressionTypes.Logical, S>;
// #endregion

//#region Column & Constant
export interface IColumnExprPart {
  Column: {
    SchemaName: string;
    DisplayName: string;
    DataType: ColumnDataTypes;
  };
  PrependTableName?: boolean;
  TableNameOverride?: string;
}
export const newColumnExpr = (name: string, schemaName: string, dataType: ColumnDataTypes, prefix?: string, entity?: string) => {
  let prefix_ = prefix;
  if (entity === FieldEntitiesType.User && !prefix) { // user field columns require 'user' as prefix
    prefix_ = usersPrefix;
  }
  return ({
    Type: ExpressionTypes.Column,
    SubType: null,
    Part: {
      Column: {
        SchemaName: schemaName,
        DisplayName: name,
        DataType: dataType,
      },
      PrependTableName: prefix_ ? true : undefined,
      TableNameOverride: prefix_ || undefined,
    },
  } as IExpression<ExpressionTypes.Column>);
};

type ConstantValueType<T extends ColumnDataTypes> =
  T extends ColumnDataTypes.String
    ? string
    : T extends ColumnDataTypes.Time
    ? string
    : T extends ColumnDataTypes.DateTime
    ? string
    : T extends ColumnDataTypes.Default
    ? number
    : T extends ColumnDataTypes.Int
    ? number
    : T extends ColumnDataTypes.Int64
    ? number
    : T extends ColumnDataTypes.Decimal
    ? number
    : T extends ColumnDataTypes.Boolean
    ? boolean
    : T extends ColumnDataTypes.StringArray
    ? string[]
    : T extends ColumnDataTypes.Int64Array
    ? number[]
    : never;

export interface IConstantExprPart<
  T extends ColumnDataTypes = ColumnDataTypes
> {
  Value: ConstantValueType<T>;
  DataType: T;
}
export const newConstantExpr = <T extends ColumnDataTypes>(
  value: ConstantValueType<T>,
  dataType: T,
) => ({
    Type: ExpressionTypes.Constant,
    SubType: null,
    Part: { Value: value, DataType: dataType },
  } as IExpression<ExpressionTypes.Constant>);

export interface IKeywordExprPart {}
export const newNullExpr = (): IExpression<ExpressionTypes.Keyword, KeywordExprTypes.Null> => ({
  Type: ExpressionTypes.Keyword,
  SubType: KeywordExprTypes.Null,
  Part: {},
});
//#endregion

//#region AggExpr
export interface ISumExprPart {
  ExpressionToSum: IExpression;
}
export interface ISumIfExprPart extends ISumExprPart, IConditionPartBase {}
export const newSumExpr = (summand: IExpression, comparison?: IExpression) => ({
  Type: ExpressionTypes.Aggregation,
  SubType: comparison ? AggExprTypes.SumIf : AggExprTypes.Sum,
  Part: comparison
    ? { ExpressionToSum: summand, Condition: comparison }
    : { ExpressionToSum: summand },
} as IAggExpr<AggExprTypes.SumIf | AggExprTypes.Sum>);

export interface IMaxExprPart { MaxOf: IExpression }
export interface IMaxIfExprPart extends IConditionPartBase { ExpressionToMax: IExpression }
export const newMaxExpr = (exprToMax: IExpression, comparison?: IExpression) => ({
  Type: ExpressionTypes.Aggregation,
  SubType: comparison ? AggExprTypes.MaxIf : AggExprTypes.Max,
  Part: comparison
    ? { ExpressionToMax: exprToMax, Condition: comparison }
    : { MaxOf: exprToMax },
} as IAggExpr<AggExprTypes.Max | AggExprTypes.MaxIf>);

export interface IMinExprPart { MinOf: IExpression }
export interface IMinIfExprPart extends IConditionPartBase { ExpressionToMin: IExpression }
export const newMinExpr = (exprToMin: IExpression, comparison?: IExpression) => ({
  Type: ExpressionTypes.Aggregation,
  SubType: comparison ? AggExprTypes.MinIf : AggExprTypes.Min,
  Part: comparison
    ? { ExpressionToMin: exprToMin, Condition: comparison }
    : { MinOf: exprToMin },
} as IAggExpr<AggExprTypes.Min | AggExprTypes.MinIf>);

export interface IAvgExprPart {
  ExpressionToAvg: IExpression;
}
export interface IAvgIfExprPart extends IAvgExprPart, IConditionPartBase {}
export const newAvgExpr = (exprToAvg: IExpression, comparison?: IExpression) => ({
  Type: ExpressionTypes.Aggregation,
  SubType: comparison ? AggExprTypes.AvgIf : AggExprTypes.Avg,
  Part: comparison
    ? { ExpressionToAvg: exprToAvg, Condition: comparison }
    : { ExpressionToAvg: exprToAvg },
} as IAggExpr<AggExprTypes.Avg | AggExprTypes.AvgIf>);

export interface ICountExprPart {
  ElementToCount: IExpression;
}
export interface ICountIfExprPart extends IConditionPartBase {}
export interface ICountDistinctExprPart {
  ExpressionToCount: IExpression;
}
export interface ICountDistinctIfExprPart
  extends ICountDistinctExprPart,
    IConditionPartBase {}
export const newCountExpr = (
  exprToCount: IExpression | null,
  distinct?: boolean,
  comparison?: IExpression,
) => {
  if (distinct) {
    return {
      Type: ExpressionTypes.Aggregation,
      SubType: comparison
        ? AggExprTypes.CountDistinctIf
        : AggExprTypes.CountDistinct,
      Part: comparison
        ? { ExpressionToCount: exprToCount, Condition: comparison }
        : { ExpressionToCount: exprToCount },
    } as IAggExpr<AggExprTypes.CountDistinct | AggExprTypes.CountDistinctIf>;
  }

  return {
    Type: ExpressionTypes.Aggregation,
    SubType: comparison ? AggExprTypes.CountIf : AggExprTypes.Count,
    Part: comparison
      ? { Condition: comparison }
      : { ElementToCount: exprToCount },
  } as IAggExpr<AggExprTypes.Count | AggExprTypes.CountIf>;
};
//#endregion

//#region Arithmetic
export interface IPlusExprPart { ItemsToAdd: IExpression[] }
export const newPlusExpr = (...items: IExpression[]): IArithmeticExpr<ArithmeticExprTypes.Plus> => ({
  Type: ExpressionTypes.Arithmetic,
  SubType: ArithmeticExprTypes.Plus,
  Part: { ItemsToAdd: items },
});

export interface IMinusExprPart { Minuend: IExpression, Subtrahend: IExpression }
export const newMinusExpr = (...items: IExpression[]): IArithmeticExpr<ArithmeticExprTypes.Minus> => (
  items.slice(2).reduce<IArithmeticExpr<ArithmeticExprTypes.Minus>>(
    (prev, curr) => newMinusBinaryExpr(prev, curr) as IArithmeticExpr<ArithmeticExprTypes.Minus>,
    newMinusBinaryExpr(items[0] ?? newConstantExpr(0, ColumnDataTypes.Int), items[1] ?? newConstantExpr(0, ColumnDataTypes.Int)),
  )
);
export const newMinusBinaryExpr = (minuend: IExpression, subtrahend: IExpression): IArithmeticExpr<ArithmeticExprTypes.Minus> => ({
  Type: ExpressionTypes.Arithmetic,
  SubType: ArithmeticExprTypes.Minus,
  Part: { Minuend: minuend, Subtrahend: subtrahend },
});

export interface IMultiplyExprPart { Multiplicand: IExpression, Multiplier: IExpression }
export const newMultiplyExpr = (multiplicand: IExpression, multiplier: IExpression): IArithmeticExpr<ArithmeticExprTypes.Multiply> => ({
  Type: ExpressionTypes.Arithmetic,
  SubType: ArithmeticExprTypes.Multiply,
  Part: { Multiplicand: multiplicand, Multiplier: multiplier },
});

export interface IDivideExprPart { Dividend: IExpression, Divisor: IExpression }
export const newDivideExpr = (dividend: IExpression, divisor: IExpression): IArithmeticExpr<ArithmeticExprTypes.Divide> => ({
  Type: ExpressionTypes.Arithmetic,
  SubType: ArithmeticExprTypes.Divide,
  Part: { Dividend: dividend, Divisor: divisor },
});
//#endregion

//#region CompExpr
export interface IComparisonExprPart<C extends CompExprTypes> {
  LHS: IExpression;
  RHS: IExpression;
  Operator: C;
}
export const newComparisonExpr = <C extends CompExprTypes>(lhs: IExpression, rhs: IExpression, operator: C) => ({
  Type: ExpressionTypes.Comparison,
  SubType: operator,
  Part: { LHS: lhs, RHS: rhs, Operator: operator },
} as ICompExpr<C>);
//#endregion

//#region LogicalExpr
export interface IAndExprPart { Conditions: Array<IExpression> }
export interface IOrExprPart { Conditions: Array<IExpression> }
export interface INotExprPart { Expression: IExpression }
export interface IIsNullExprPart { Expression: IExpression }
export const newAndExpr = (conditions: IExpression[]): IExpression<ExpressionTypes.Logical, LogicalExprTypes.And> => ({
  Type: ExpressionTypes.Logical, SubType: LogicalExprTypes.And, Part: { Conditions: conditions },
});
export const newOrExpr = (conditions: IExpression[]): IExpression<ExpressionTypes.Logical, LogicalExprTypes.Or> => ({
  Type: ExpressionTypes.Logical, SubType: LogicalExprTypes.Or, Part: { Conditions: conditions },
});
export const newNotExpr = (expr: IExpression): IExpression<ExpressionTypes.Logical, LogicalExprTypes.Not> => ({
  Type: ExpressionTypes.Logical, SubType: LogicalExprTypes.Not, Part: { Expression: expr },
});
export const newIsNullExpr = (expr: IExpression): IExpression<ExpressionTypes.Logical, LogicalExprTypes.IsNull> => ({
  Type: ExpressionTypes.Logical, SubType: LogicalExprTypes.IsNull, Part: { Expression: expr },
});
//#endregion

//#region DateExpr
type IDateExpr<S extends ExpressionSubTypes<ExpressionTypes.Date>> =
  IExpression<ExpressionTypes.Date, S>;
export interface IDateConvertExprPart {
  DateTime: IExpression;
}
export interface IDateFnExprPart {
  DateTime?: IExpression;
  DurationType?: TimeDuration;
  Duration?: number;
}
export interface IDateDiffFnExprPart {
  LHS: IExpression;
  RHS: IExpression;
  Unit?: TimeDuration;
  Bounded?: boolean;
}

export const newDateExpr = (
  binningType: DateBinningFormatters,
  dateExpr: IExpression,
): IDateExpr<DateBinningFormatters> => ({
  Type: ExpressionTypes.Date,
  SubType: binningType,
  Part: {
    DateTime: dateExpr,
  },
});

export const newDateFnExpr = (fnType: DateFnExprTypes, dateExpr: IExpression, duration: number, durationType: TimeDuration)
  : IDateExpr<DateFnExprTypes> => ({
  Type: ExpressionTypes.Date,
  SubType: fnType,
  Part: {
    DateTime: dateExpr,
    DurationType: durationType,
    Duration: duration,
  },
});

export const newDateNowExpr = (): IDateExpr<DateFnExprTypes> => ({ Type: ExpressionTypes.Date, SubType: DateFnExprTypes.CurrentDateTime, Part: {} });

export const newDateDiffExpr = (lhs: IExpression, rhs: IExpression, Unit: TimeDuration, bounded: boolean)
  : IDateExpr<DateDiffFnExprTypes> => ({
  Type: ExpressionTypes.Date,
  SubType: DateDiffFnExprTypes.DifferenceExpr,
  Part: {
    LHS: lhs,
    RHS: rhs,
    Unit,
    Bounded: bounded, // for DateDiff -> true
  },
});

//#endregion

export interface IArrayJoinExprPart { Array: IExpression }
export const newArrayJoinExpr = (column: IExpression): IExpression<ExpressionTypes.ArrayFn, ArrayFnExprTypes.ArrayJoinExpr> => ({
  Type: ExpressionTypes.ArrayFn,
  SubType: ArrayFnExprTypes.ArrayJoinExpr,
  Part: { Array: column },
});

export interface IConfigExprPart {
  Namespace: ConfigExprNamespace; // defines where to look for config (user, tenant settings, etc.)
  Key: TenantSettingKey; // defines the exact config's value to substitute in query
}
export const newConfigExpr = (namespace: ConfigExprNamespace, key: TenantSettingKey): IExpression<ExpressionTypes.Config> => ({
  Type: ExpressionTypes.Config,
  SubType: null,
  Part: {
    Namespace: namespace, Key: key,
  },
});

export const newIfExpr = (
  conditionExpr: IExpression,
  thenPart: IExpression,
  elsePart: IExpression,
): IExpression => ({
  Type: ExpressionTypes.Condition,
  SubType: ConditionExprTypes.If,
  Part: {
    Condition: conditionExpr,
    ThenPart: thenPart,
    ElsePart: elsePart,
  },
});

//#region Type Guards

export type IAggExprGuarded =
  IAggExpr<AggExprTypes.Sum> | IAggExpr<AggExprTypes.SumIf>
  | IAggExpr<AggExprTypes.Max> | IAggExpr<AggExprTypes.MaxIf>
  | IAggExpr<AggExprTypes.Min> | IAggExpr<AggExprTypes.MinIf>
  | IAggExpr<AggExprTypes.Avg> | IAggExpr<AggExprTypes.AvgIf>
  | IAggExpr<AggExprTypes.Count> | IAggExpr<AggExprTypes.CountIf>
  | IAggExpr<AggExprTypes.CountDistinct> | IAggExpr<AggExprTypes.CountDistinctIf>;

export type IArithmeticExprGuarded =
  IArithmeticExpr<ArithmeticExprTypes.Plus> | IArithmeticExpr<ArithmeticExprTypes.Minus>
  | IArithmeticExpr<ArithmeticExprTypes.Multiply> | IArithmeticExpr<ArithmeticExprTypes.Divide>;

export type ILogicalExprGuarded =
  ILogicalExpr<LogicalExprTypes.And> | ILogicalExpr<LogicalExprTypes.Or>
  | ILogicalExpr<LogicalExprTypes.Not> | ILogicalExpr<LogicalExprTypes.IsNull>;

export type IExpressionGuarded =
  IExpression<ExpressionTypes.Column> | IExpression<ExpressionTypes.Constant>
  | IExpression<ExpressionTypes.Config>
  | IExpression<ExpressionTypes.Keyword, KeywordExprTypes.Null>
  | IAggExprGuarded
  | IArithmeticExprGuarded
  | ICompExpr<CompExprTypes>
  | ILogicalExprGuarded
  | IDateExpr<DateBinningFormatters> | IDateExpr<DateFnExprTypes> | IDateExpr<DateDiffFnExprTypes>
  | IExpression<ExpressionTypes.ArrayFn, ArrayFnExprTypes.ArrayJoinExpr>
  | IExpression<ExpressionTypes.Condition>;

export const isColumn = (expr: IExpression): expr is IExpression<ExpressionTypes.Column> => expr.Type === ExpressionTypes.Column;
export const isConstant = (expr: IExpression): expr is IExpression<ExpressionTypes.Constant> => expr.Type === ExpressionTypes.Constant;
export const isConfig = (expr: IExpression): expr is IExpression<ExpressionTypes.Config> => expr.Type === ExpressionTypes.Config;
export const isKeyword = (expr: IExpression): expr is IExpression<ExpressionTypes.Keyword> => expr.Type === ExpressionTypes.Keyword;
export const isNull = (expr: IExpression): expr is IExpression<ExpressionTypes.Keyword, KeywordExprTypes.Null> => isKeyword(expr) && expr.SubType === KeywordExprTypes.Null;
export const isAgg = (expr: IExpression): expr is IAggExpr<AggExprTypes> => expr?.Type === ExpressionTypes.Aggregation;
export const isSum = (expr: IExpression): expr is IAggExpr<AggExprTypes.Sum> => isAgg(expr) && expr.SubType === AggExprTypes.Sum;
export const isSumIf = (expr: IExpression): expr is IAggExpr<AggExprTypes.SumIf> => isAgg(expr) && expr.SubType === AggExprTypes.SumIf;
export const isMax = (expr: IExpression): expr is IAggExpr<AggExprTypes.Max> => isAgg(expr) && expr.SubType === AggExprTypes.Max;
export const isMaxIf = (expr: IExpression): expr is IAggExpr<AggExprTypes.MaxIf> => isAgg(expr) && expr.SubType === AggExprTypes.MaxIf;
export const isMin = (expr: IExpression): expr is IAggExpr<AggExprTypes.Min> => isAgg(expr) && expr.SubType === AggExprTypes.Min;
export const isMinIf = (expr: IExpression): expr is IAggExpr<AggExprTypes.MinIf> => isAgg(expr) && expr.SubType === AggExprTypes.MinIf;
export const isAvg = (expr: IExpression): expr is IAggExpr<AggExprTypes.Avg> => isAgg(expr) && expr.SubType === AggExprTypes.Avg;
export const isAvgIf = (expr: IExpression): expr is IAggExpr<AggExprTypes.AvgIf> => isAgg(expr) && expr.SubType === AggExprTypes.AvgIf;
export const isCount = (expr: IExpression): expr is IAggExpr<AggExprTypes.Count> => isAgg(expr) && expr.SubType === AggExprTypes.Count;
export const isCountIf = (expr: IExpression): expr is IAggExpr<AggExprTypes.CountIf> => isAgg(expr) && expr.SubType === AggExprTypes.CountIf;
export const isCountDistinct = (expr: IExpression): expr is IAggExpr<AggExprTypes.CountDistinct> => isAgg(expr) && expr.SubType === AggExprTypes.CountDistinct;
export const isCountDistinctIf = (expr: IExpression): expr is IAggExpr<AggExprTypes.CountDistinctIf> => isAgg(expr) && expr.SubType === AggExprTypes.CountDistinctIf;
export const isArithmetic = (expr: IExpression): expr is IArithmeticExpr => expr.Type === ExpressionTypes.Arithmetic;
export const isPlus = (expr: IExpression): expr is IArithmeticExpr<ArithmeticExprTypes.Plus> => isArithmetic(expr) && expr.SubType === ArithmeticExprTypes.Plus;
export const isMinus = (expr: IExpression): expr is IArithmeticExpr<ArithmeticExprTypes.Minus> => isArithmetic(expr) && expr.SubType === ArithmeticExprTypes.Minus;
export const isMultiply = (expr: IExpression): expr is IArithmeticExpr<ArithmeticExprTypes.Multiply> => isArithmetic(expr) && expr.SubType === ArithmeticExprTypes.Multiply;
export const isDivide = (expr: IExpression): expr is IArithmeticExpr<ArithmeticExprTypes.Divide> => isArithmetic(expr) && expr.SubType === ArithmeticExprTypes.Divide;
export const isComp = (expr: IExpression): expr is ICompExpr<CompExprTypes> => expr.Type === ExpressionTypes.Comparison;
export const isLogical = (expr: IExpression): expr is IExpression<ExpressionTypes.Logical> => expr.Type === ExpressionTypes.Logical;
export const isAnd = (expr: IExpression): expr is IExpression<ExpressionTypes.Logical, LogicalExprTypes.And> => isLogical(expr) && expr.SubType === LogicalExprTypes.And;
export const isOr = (expr: IExpression): expr is IExpression<ExpressionTypes.Logical, LogicalExprTypes.Or> => isLogical(expr) && expr.SubType === LogicalExprTypes.Or;
export const isNot = (expr: IExpression): expr is IExpression<ExpressionTypes.Logical, LogicalExprTypes.Not> => isLogical(expr) && expr.SubType === LogicalExprTypes.Not;
export const isIsNull = (expr: IExpression): expr is IExpression<ExpressionTypes.Logical, LogicalExprTypes.IsNull> => isLogical(expr) && expr.SubType === LogicalExprTypes.IsNull;
export const isDate = (expr: IExpression): expr is IDateExpr<DateExprTypes> => expr.Type === ExpressionTypes.Date;
export const isDateFn = (expr: IExpression): expr is IDateExpr<DateFnExprTypes> => isDate(expr) && (expr.SubType === DateFnExprTypes.Subtract || expr.SubType === DateFnExprTypes.Add || expr.SubType === DateFnExprTypes.CurrentDateTime);
export const isDateDiffFn = (expr: IExpression): expr is IDateExpr<DateDiffFnExprTypes> => isDate(expr) && (expr.SubType === DateDiffFnExprTypes.DifferenceExpr);
export const isArrayFn = (expr: IExpression): expr is IExpression<ExpressionTypes.ArrayFn> => expr.Type === ExpressionTypes.ArrayFn;
export const isArrayJoinFn = (expr: IExpression): expr is IExpression<ExpressionTypes.ArrayFn, ArrayFnExprTypes.ArrayJoinExpr> => isArrayFn(expr) && expr.SubType === ArrayFnExprTypes.ArrayJoinExpr;
export const isIf = (expr: IExpression): expr is IExpression<ExpressionTypes.Condition> => expr.Type === ExpressionTypes.Condition && (expr.SubType === ConditionExprTypes.If);

//#endregion
