const localizationObjectEnUs = {
  cmn: {
    edited: 'edited',
    deleted: 'deleted',
    cloned: 'cloned',
    allSelected: 'All Selected',
    countSelected: '{0} Selected',
    copy: 'Copy',
    filters: 'Filters',
    filter: 'Filter',
    noFilterAvailable: 'No filter available',
    filterDesc: 'Filter your report data using the below fields.',
    visualizationSettings: 'Visualization Settings',
    visualize: 'Visualize',
    settingsDesc: 'Modify rows and columns to be viewed in your report.',
    zeroValues: 'Zero Values',
    zeroValuesDesc: 'View rows with zero values in report. This may impact report load time.',
    zeroValuesTitle: 'Show records with no values',
    pinnedReports: 'Pinned Reports',
    reset: 'Reset',
    tryAgain: 'Try Again',
    new: 'New',
    modified: 'Modified',
    dataRefreshed: 'Data Refreshed',
    refresh: 'Refresh Interval',
    loading: 'Loading',
    return: 'Return',
    logOut: 'Log Out',
    dataRefreshInterval: 'Data Refresh Interval',
    loadingOptions: 'Loading Options',
    reportNotLoadCorrect: 'The report has not loaded correctly.',
    goBackDashboard: 'Go back to Dashboard',
    goHomePage: 'Go to Home Page',
    goReportPage: 'Go to Report Page',
    goReportHome: 'Back to Reports Home',
    contactAdmin: 'Contact your admin for more information',
    errorMessage404: "The page you are looking for doesn't exist. Try going back again or go to Home Page.",
    back: 'Back',
    applySettings: 'Apply Settings',
    applyChanges: 'Apply Changes',
    addFieldGrouping: 'Add fields for grouping',
    clickGoBack: 'Click here to go back',
    saveChanges: 'Save Changes',
    save: 'Save',
    saveAs: 'Save As',
    reportCantbeSaved: 'This report can\'t be overwritten as it is a system report.',
    cancel: 'Cancel',
    undo: 'Undo',
    noPinnedReports: 'No Pinned Reports',
    noPinnedDashboard: 'No Pinned Dashboard',
    pinDashboardForQuickAccess: 'Pin all your frequently viewed dashboards for a quick access.',
    pinAll: 'Pin all your frequently viewed reports for a quick access.',
    noReportsAvailable: 'No Reports Available',
    noReportsFnd: 'No Reports Found',
    noReportFndDesc: 'The reports you are looking was not found.',
    noDashobard: 'No Dashboard Available',
    noHomeDashboard: 'No Home Dashboard',
    askAdminToCreateDashboard: 'Ask your Admin to set a Home dashboard for you.',
    createDashbord: 'Create a Dashboard to populate this section.',
    noDashboardSrch: 'No Dashboard Found',
    dashboardSrchDesc: 'The dashboard you are looking was not found.',
    createReport: 'Create a Report to populate this section.',
    pinnedRepDesc: 'Access all your pinned reports here.',
    pinReportEmptyMsg: 'Pin all your frequently viewed reports for a quick access.',
    dataUpdated: 'The data has been updated',
    dataUpdatedMsg: 'Data Updated',
    noExport: 'No Export Yet.',
    sorrySWW: 'Sorry, Something Went Wrong',
    v1ReportrvError: 'Either this dashboard does not exist or you do not have the permission to access it. Click on try again to reload the page.',
    pleaseTryAgain: '{0} Click on try again or choose another report from the listing page.',
    goToListingPage: 'Go To Listing Page',
    fields: 'Fields',
    values: 'Values',
    Pin: 'Pin',
    Unpin: 'Unpin',
    select: 'Select',
    rowGroupingDesc: 'Change the way the rows are grouped. Drag and Drop to change the order.',
    columnGroupingDesc: 'Change the way the columns are grouped. Drag and Drop to change the order.',
    drilldownColumnDesc: 'Change the way the drilldown columns are grouped. Use Drag and Drop to change the order.',
    addValuesDesc: 'Change the way the values are arranged. Use Drag and Drop to change the order.',
    disclamer: 'Disclaimer: ',
    edit: 'Edit',
    expand: 'Expand',
    collapse: 'Collapse',
    sieraReports: 'SIERA is LeadSquared\'s new self-serve reporting platform',
    toEnableSiera: 'To enable SIERA for your account, contact your account manager',
    copyEmbededUrl: 'Copy Embed URL',
    embedURLCopied: 'Embed URL has been copied successfully.',
    visualise: 'Visualise',
    visualiseDesc: 'Select the type of visualisation for your Table & Chart.',
    chartAttr: 'Chart Attributes',
    numOfColumnsLimit: 'Number of Columns have exceeded the maximum limit. Please refer to the ',
    cumulativeLimit: ' column for the cumulative data outside the limit.',
    numOfColumnsLimitTT: 'Number of Columns have exceeded the maximum limit. The ',
    cumulativeLimitTT: ' group contains the data for the remaining columns.',
    others: 'Others',
    oppDetail: 'Opportunity Details',
    leadDetail: 'Lead Details',
    viz: 'Visualization',
    type: 'Type',
    data: 'Data',
    summary: 'Summary',
    subTotals: 'Subtotal',
    none: 'None',
    selectAll: 'Select All',
    deselectAll: 'Deselect All',
    chooseProfile: 'Choose profile :',
    profileAdded: 'Profile Added',
    viewProfileAs: 'View profile as :',
    newProfile: 'New Profile',
    searchAnyReport: 'Search any report',
    searchAnyDashboard: 'Search any dashboard',
    noDescription: '(No description)',
    unPinReport: 'Click here to unpin the report',
    pinReport: 'Click here to pin the report',
    unPinDashboard: 'Click here to unpin the dashboard',
    pinDashboard: 'Click here to pin the dashboard',
    adminPinDashboard: 'You cannot unpin this dashboard as it has been pinned by your Admin.',
    homeDashboard: 'Home Dashboards',
    selectDashboard: 'Select dashboard:',
    adminPinReport: 'You cannot unpin this report as it has been pinned by your Admin.',
    delete: 'Delete',
    v1PinnedReports: 'View all your pinned reports.',
    v1AllReports: 'View all your reports.',
    v1AllDashboards: 'View all your dashboards.',
    v1SaveReportsBtn: 'Manage all your saved reports!',
    displayName: 'Display Name',
    editFilter: 'Edit Filter',
    searchReports: 'Search Reports',
    searchDashboards: 'Search Dashboards',
    untaggedReports: 'Untagged Reports',
    untaggedDashboards: 'Untagged Dashboards',
    preview: 'Preview',
    enterName: 'Ingresa un nombre',
    enterDesc: 'Introduce una descripción',
    errorInvalidInput: 'Please enter valid input',
    disableSaveOnSharedReport: 'This report can’t be overwritten as you are not the owner of this report.',
    saveAsDisableWhenPermissionApplied: "Report can't be saved as Permission Templates are applied",
    warning: 'Warning',
    deleteConfirmText: 'Yes, Delete',
    no: 'No',
    disableDeleteOnSharedReport: 'This report can’t be {0} by you as you are not the owner of this report.',
    reportCantbeDeleted: 'This report can\'t be {0} as it is a system report.',
    searchWidget: 'Search Widgets',
    enter: 'Enter',
    button: 'button',
    disableEdit: 'This report can\'t be edited.',
    reportingDisabledmsg: 'You do not have permissions to access reports.',
    enableReporting: 'To enable report access, please contact your administrator.',
    emptyBookmarkedReports: 'No Bookmarked Reports.',
    createBookmark: 'Bookmark a report to populate this section.',
    copyOf: 'Copy of',
    sieraTour: 'SIERA Tour',
    listingPageTour: 'Listing Page Tour',
    giveFeedback: 'Give Feedback',
    reportAnIssue: 'Report An Issue',
    userLimit: 'User Limit: ',
    accountLimit: 'Account Limit: ',
    onDay: 'On day',
    onThe: 'On the',
    schedule: 'Schedule',
    startDate: 'Start Date',
    endDate: 'End Date',
    frequency: 'Frequency',
    scheduledTime: 'Scheduled Time',
    repeatEvery: 'Repeat Every',
    emptyScheduledReports: 'No Scheduled Reports',
    createSchedule: 'Click here to view how to schedule a report.',
    stop: 'Stop',
    lastRunOn: 'Last Run On',
    status: 'Status',
    owner: 'Owner',
    modifiedOn: 'Modified On',
    scheduleFilterSubtitle: 'Filter the schedules on the below fields.',
    scheduleUserLimit: 'This shows your current active schedules. You cannot schedule more than the prescribed limit.',
    scheduleAccountLimit: 'This shows the current active schedules for the account. You cannot schedule more than the prescribed limit.',
    scheduleLimit: 'You cannot schedule more than the prescribed limit.',
    scheduleUpdateSuccess: 'Schedule updated successfully.',
    scheduleDeleteSuccess: 'Schedule stopped successfully.',
    disableChartTooltip: 'The feature is not available for this report.',
    NoReportsDescription: 'There are no reports available. Try changing the report type filter for what you are looking for.',
    drilldown: 'Drilldown',
    configureDrilldown: 'Configure Drilldown',
    configureDrilldownDesc: 'Switch on the toggle button to configure the drilldown.',
    configureYourDrilldown: 'Configure your drilldown:',
    configureYourDrilldownDesc: 'Create a config to define the drilldown columns for the values.',
    configure: 'Configure',
    addValuesToConfigure: 'Add values to configure',
    addFieldsToConfigure: 'Add fields to configure',
    history: 'History',
    schedulingHistory: 'Scheduling History',
    scheduleConfiguration: '1. Schedule Configuration:',
    scheduleFor: '2. Scheduling for:',
    scheduleForSelf: 'Schedule for myself',
    scheduleForOthers: 'Schedule for others',
    ownerName: 'Owner Name',
    oldValue: 'Old Value',
    newValue: 'New Value',
    deletedReportScheduleMessage: 'Schedule is disabled as this report has been deleted',
    monthlyAbsoluteDaySelectionMessage: 'Some months have fewer than 31 days. For these months, the occurrence will fall on the last day of the month.',
    scheduleHistoryEmptyTitle: 'No Schedule History Available',
    scheduleHistoryEmptySubtitle: 'No schedule history is available to display.',
    schedulingMode: 'Schedule Mode',
    accessDenied: 'Access Denied',
    limitReached: 'Limit Reached',
    buyAddOn: 'Buy Add-On',
    dataNotAvailable: 'Data not available',
    productLineRestrictionMsg: 'The data for this report is no longer available as ‘{0}’ reports have been disabled for you. You can subscribe to ‘{1}’ reports from ‘Manage Subscriptions’.',
    manageSubscriptions: 'Manage Subscriptions',
    addOn: 'Add-On',
    routeDisabledMsg: 'Either this module is disabled or has reached its usage limit. Please contact your administrator to request access or resolve this issue.',
    changeAndContinue: 'Yes, change & continue',
    changeAndSave: 'Yes, change & save',
    emptyCustomReports: 'No Custom Reports',
    emptyCustomDashboards: 'No Custom Dashboards',
    createCustomReports: 'Click here to view how to create a custom report.',
    createCustomDashboards: 'Click here to view how to create a custom dashboard.',
  },
  profile: {
    applyAndSaveProfile: 'Apply & Save Profile',
    enterNameProfile: 'Enter name for the profile',
    onlySpecificRoleGroup: 'Only specific teams, roles, groups or users',
    everyone: 'Everyone',
    profileVisible: 'Profile will be shared to :',
    pinChosenReports: 'Pin chosen reports or dashboards for the selected users',
    enterVisibility: '1. Enter sharing criteria',
    pinProfileReport: 'Pin chosen reports or dashboards for the selected users',
    chooseReportDashboard: '2. Select the reports or dashboards to add to the profile',
    profileLeftSelectInfoLabel: 'Share the profile with :',
    selectTeams: 'Select Teams',
    createNewProfile: 'Creating a new profile',
    editProfile: 'Edit Profile',
    allReports: 'All Reports',
    allDashboards: 'All Dashboards',
    editTags: 'Edit Tag(s)...',
    editDashboardTags: 'Edit Dashboard Tags',
    editReportTags: 'Edit Report Tags',
    editTagsToast: 'Report tags have been modified successfully.',
    editTagsDashboardToast: 'Dashboard tags have been modified successfully.',
    searchTag: 'Search Tag',
    newReportAdded: 'New Report Added',
    reportRemoved: 'Report Removed',
    pinReportToProfile: 'Click here to pin this report to the profile',
    deleteProfile: 'Click here to delete this profile',
    unpinReportToProfile: 'Click here to unpin this report from the profile',
    failedSaveProfile: 'Failed to save the Profile. Please try again.',
    successSaveProfile: 'New Profile has been created successfully',
    deleteSuccess: 'Profile Deleted Successfully',
    clickToCheckAccess: 'Click to check who has access to this profile',
    visibleTo: 'Profile shared to :',
    noteCrossIcon: 'Note : You can click on the cross icon to remove an attribute from the sharing criteria.',
    noSelection: 'No {0} selected',
    removeAllUser: 'Remove all selected users',
    removingAllUser: 'Removing all selected users...',
    addSuccess: 'New Profile has been created successfully.',
    updtSucess: 'Profile has been modified successfully.',
    setAsHomeDashboard: 'Click here to set this dashboard as home dashboard.',
    unsetAsHomeDashboard: 'Click here to remove this dashboard as home dashboard.',
    home: 'Home',
    linkedReportTooltip: 'Cannot deselect this report as it is a part of one or more selected Dashboards. Please uncheck the Dashboard(s) and try again.',
    pinDashboardToProfile: 'Click here to pin this dashboard to the profile',
    unpinDashboardToProfile: 'Click here to unpin this dashboard to the profile',
    deleting: 'Deleting',
    emptyReportTags: 'Assign a tag to a report to populate this section. Check out the All Reports section to access all available reports.',
    dashboardNotification: 'Note : Saving the Profile will share the reports corresponding to the selected dashboards. Removing a Dashboard from the Profile will not automatically remove the corresponding Reports.',
    newDashboardAdded: 'New Dashboard Added',
    dashboardRemoved: 'Dashboard Removed',
    noDashboardTags: 'Add a Dashboard to this tag to populate this section.',
    noHomeDashboardTags: 'Only Admins can select Home Dashboards for other users.',
    v1Actions: 'Actions',
    deleteProfileText: 'Delete Profile',
    profileActions: 'Profile Actions',
    quickAccess: 'Quick Access',
    v1DeleteText: 'Are you sure you want to delete the selected Profile? Deleting this profile will delete its tags,',
    v1ProfileDeleteSuccess: 'Profile(s) Deleted Successfully.',
    close: 'Close',
    noTags: 'No Tags Found',
    bookmarkedReports: 'Bookmarked Reports',
    scheduledReports: 'Scheduled Reports',
    tableSettings: 'Table Settings',
    chartsSettings: 'Charts Settings',
    confirmProfileDeleteMessage: 'Users will no longer have access to reports and dashboards shared with them solely via this profile if the profile is deleted.',
    profileDeleteSuccess: 'Profile has been deleted successfully.',
    recipientEmailText: 'Enter recipient’s email',
    recipientGroupSeparator: '- or -',
  },
  err: {
    reportNameAndDesc: 'Pease enter valid report name and description',
    noData: 'No Data Available',
    noPage: 'Page Not Found',
    noPage404: '404 - Page Not Found',
    noPermission: 'Sorry, you do not have permission to access SIERA',
    sameName: 'A value with this name already exists',
    invalidName: '*You have entered an invalid character. Please enter a valid name.',
    reportVisualizationNotCorrect: 'The report visualisation did not load correctly.',
    savedReportsFailed: 'Failed to open Saved Reports.',
    profileInfoNotLoaded: 'The page did not load correctly. Click on try again or go to the listing page.',
    plzTryAgain: 'Please try again.',
    reportFilterNotCorrect: 'The Report filters did not load correctly.',
    reportExportNotCorrect: 'Exported reports did not load correctly.',
    reportCannotOpen: 'Sorry, We cannot open this report.',
    duplicateName: 'This name is already in use. Try using other name.',
    noDataForSelFilters: 'There are no data to display for the selected filter. Try adjusting the filter for what you are looking for.',
    noFieldsCurrentAvail: 'No fields are currently available in this section.',
    noFiltersCurrentAvail: 'No filters are currently available in this section.',
    noFilters: 'No filters found.',
    noFields: 'No fields found.',
    noItems: 'No Items Found.',
    noValues: 'No values found.',
    failedToRedirect: 'Failed to redirect to the report. Please try again.',
    emptyTags: 'No tags present under this name.',
    inValidCharacterMessage: '*These characters cannot be used: ` ; \' " < > & . \\',
    reportListingLoadErrorMessage: 'Reports list failed to load correctly, please try again or report an issue to support.',
    dashboardListingLoadErrorMessage: 'Dashboards list failed to load correctly, please try again or report an issue to support.',
    duplicateText: '*Profile with same name already exists',
    profileNameMaxLength: '*You cannot enter a profile name greater than 40 characters in length.',
    filterNameMaxLength: '*You cannot enter a filter name greater than 40 characters in length.',
    reportDescMaxLength: '*You cannot enter a report description greater than 120 characters in length.',
    reportNameMinLength: '*You cannot enter a name less than 3 characters in length',
    reportDescMinLength: '*You cannot enter a description less than 3 characters in length',
    accessDenied: 'Either this report does not exist or you do not have the permission to access it.',
    tagsError: 'Report Tags failed to load correctly, please try again or report an issue to support.',
    noResult: 'No Result Found.',
    searching: 'Searching...',
    nameMaxLengthValidationMessage: '*You cannot enter a name greater than 60 characters in length',
    nameMinLengthValidationMessage: '*You cannot enter a name less than 3 characters in length',
    descMaxLengthValidationMessage: '*You cannot enter a description greater than 120 characters in length',
    descMinLengthValidationMessage: '*You cannot enter a description less than 3 characters in length',
    sryPlzTryAgain: 'Sorry, something went wrong. Please try again.',
    customExpNameMaxLength: '*You cannot enter a name greater than 40 characters in length.',
    defaultMaxLength: '*You cannot enter a name greater than 60 characters in length',
    customExpNameMinLength: '*You cannot enter a name less than 3 characters in length',
    reportNameMaxLength: '*You cannot enter a report name greater than 60 characters in length.',
    dashboardNameMaxLength: '*You cannot enter a dashboard name greater than 60 characters in length.',
    dashboardDescMaxLength: '*You cannot enter a dashboard description greater than 120 characters in length.',
    dashboardNameMinLength: '*You cannot enter a dashboard name less than 3 characters in length.',
    dashboardDescMinLength: '*You cannot enter a dashboard description less than 3 characters in length.',
    deleteReportErrorMessage: 'The report you are trying to access has been deleted. Click on ‘Go to listing page’ to choose another report from the listing page.',
    deleteReportError: 'The report has been deleted',
    widgetWithDeletedReport: 'The report used for this widget has been deleted.',
    deleteDashboardError: 'The dashboard has been deleted',
    deleteDashboardErrorMessage: 'The dashboard you are trying to access has been deleted. Click on ‘Go to listing page’ to choose another dashboard from the listing page.',
    widgetNameMaxLength: '*You cannot enter a widget name greater than 60 characters in length.',
    widgetNameMinLength: '*You cannot enter a widget name less than 3 characters in length.',
    dashboardFilterNameMaxLength: '*You cannot enter a name greater than 20 characters in length.',
    dashboardFilterNameMinLength: '*You cannot enter a name less than 3 characters in length',
    bookmarkReportFailedError: 'Bookmark Reports list failed to load correctly. Please try again or report an issue to',
    noPageAccess: 'You do not have permission to access this page.',
    adminOnlyAccess: 'This page is only available to administrators.',
  },
  exp: {
    csvExport: 'CSV Export',
    dailyExportLimit: 'Daily Export Limit',
    excelExport: 'Excel Export',
    exportReports: 'Export Reports',
    exportAs: 'Export As',
    export: 'Export',
    exportWaitMessage: 'Note : Exporting will take some time. Once the file is ready, it will be available in this section.',
    exportLimitReached: 'Export limit reached. Try again after {0}',
    exportInProgress: 'Export in progress',
    quickCsvExp: 'Quick CSV Export',
    quickXlsxExport: 'Quick XLSX Export',
    pngExport: 'Export Chart as PNG',
    jpgExport: 'Export Chart as JPG',
    pdfExport: 'Export Chart as PDF',
    expired: 'File Expired',
    inProgress: 'Export in progress...',
    failed: 'Process failed',
    expiresIn: 'Expires in: {0}',
  },
  fltr: {
    typeHereEnter: 'Type here and enter',
    addRowGrouping: 'Add Row Grouping',
    addColumnGrouping: 'Add Column Grouping',
    allFiltersAdded: 'All filters have been added',
    appliedFilters: 'Applied Filters',
    resetFilters: 'Reset Filters',
    applyFilters: 'Apply Filters',
    noFilterApplied: 'No filters applied in this section',
    defaultFilter: 'Default Filters',
    userFilters: 'User Filters',
    additionalFilters: 'Additional Filters',
    disabledFilters: 'Disabled Filters',
    filterWillBeActive: 'These filters will be active when associated fields in grouping are checked.',
    addValues: 'Add Values',
    stringFilterWarning: 'You have {0} string field(s) as grouping. This date filter cannot be more than {1} days.',
    stringFilterDateRangeRestrictionMsg: 'Relative date ranges are not permitted. Use custom range feature to select any other date range.',
    stringFieldWarning: 'Applying this field will change',
    willAffectDate: 'date range to the last {0} days only.',
    widgetsStringWarning: 'You have {0} widget with string(s) field grouping. This date filter cannot be more than {1} days and will affect all linked widgets.',
    filterHeaderMobile: 'Filter your report data using the below fields.',
    NoReportSelected: 'No reports selected',
  },
  pgn: {
    rowsPerPage: 'Rows Per Page',
    page: 'Page',
    prev: 'Prev',
    next: 'Next',
    recordsRetrieved: '{0} Records Retrieved',
    pagination: 'Pagination',
  },
  psn: {
    allColumnsAdded: 'All columns have been added',
    rowGrouping: 'Row Grouping',
    columnGrouping: 'Column Grouping',
    values: 'Values',
    addFilters: 'Add Filters',
    allMeasuresAdded: 'All measures have been added',
    maxFltrReached: 'Maximum limit of filters ({0}) have been reached',
    maxFldReached: 'Maximum limit of fields ({0}) have been reached',
    maxValReached: 'Maximum limit of values ({0}) have been reached',
  },
  pt: {
    fltrNotAddedPT: 'Filter cannot be added due to Permission Template',
    fltrDisabledPT: 'Filter disabled due to Permission Templates',
    fldNotAddedPT: 'Field cannot be added due to Permission Template',
    ptApplied: 'Permission Template Applied',
    someDataRestricted: 'Some data has been restricted due to Permission Templates',
  },
  rv: {
    enterName: 'Enter Name',
    reportName: 'Report Name',
    reportDesc: 'Report Description',
  },
  sor: {
    all: 'All',
    sortingOrder: 'Sorting Order',
    sortingAsc: 'Report Name [A-Z]',
    sortingDesc: 'Report Name [Z-A]',
    sortingUnchangesMsg: 'This is pre-applied Sort. Sorting order can not be changed.',
    dashboardSortingAsc: 'Dashboard Name [A-Z]',
    dashboardSortingDesc: 'Dashboard Name [Z-A]',
  },
  srch: {
    searchFltr: 'Search Filters',
    searchFld: 'Search Fields',
    searchFldaAndVal: 'Search Fields and Values',
    search: 'Search',
  },
  dashboard: {
    panelError: 'Sorry, something went wrong.',
    dashboardErr: 'The Dashboard did not load correctly.',
    noDataTitle: 'No Data to Display',
    noDataDescription: 'Please update the data and check your dashboard.',
    pleaseTryAgain: 'Either this dashboard does not exist or you do not have the permission to access it. Click on try again or choose another dashboard from the listing page.',
    filterSectionDesc: 'Filter your dashboard using the below fields',
    filter: 'Filter',
    filterApplied: 'Filters Applied:',
    filterNote: 'Note: Applying Filters will affect {0} widgets.',
    filtered: 'Filtered',
    filterCleared: 'Filter Cleared',
  },
  clnd: {
    today: 'Today',
    yesterday: 'Yesterday',
    thisWeek: 'This Week',
    lastWeek: 'Last Week',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisQuarter: 'This Quarter',
    lastQuarter: 'Last Quarter',
    thisYear: 'This Year',
    lastYear: 'Last Year',
    lastSevenDays: 'Last 7 Days',
    lastThirtyDays: 'Last 30 Days',
    lastNintyDays: 'Last 90 Days',
    lastThreeSixtyFiveDays: 'Last 365 Days',
    lastSevenThirtyDays: 'Last 730 Days',
    lastOneYear: 'Last 1 Year',
    lastTwoYear: 'Last 2 Years',
  },
  jic: {
    jobInfoTextLoad: 'Eg: Any new lead, activity, call, task or opportunity will reflect in ',
    jobInfoTextMerge: 'Eg: Modifications in the parent lead and opportunity will reflect in ',
    note: 'Note :',
    jobInfoNote: ' Deleted Leads, Activities and Opportunities will sync over night.',
  },
  viz: {
    minGrpWarning: 'Minimum ({0}) dimension has to be remain selected.',
    maxGrpWarning: 'Maximum limit of ({0}) selected dimension is reached.',
    customFieldRestrictionDescOnDrag: 'You cannot add custom fields to column grouping.',
    userSalesGroupDesc1: 'If a user is present in multiple Sales Group, data for the user will reflect in each Group the user belongs to.',
    summary: 'Summary',
    userSalesGroupDesc2: ' row is the aggregation of each row in the report, and so may not match the actual number in the system.',
    theQuotes: 'The “',
  },
  charts: {
    noChart: 'No Chart',
    bar: 'Bar',
    column: 'Column',
    line: 'Line',
    area: 'Area',
    pie: 'Pie',
    donut: 'Donut',
  },
  chartAttr: {
    showLabel: 'Show Data Labels',
    stackedPercent: '100% Stacked',
    showPer: 'Show Percentage',
    stackedLine: 'Stacked Line',
    stackedBar: 'Stacked Bar',
    stackedArea: 'Stacked Area',
    stackedColumn: 'Stacked Column',
    showSharedTooltip: 'Show Shared Tooltips',
    hideLegend: 'Hide Legend',
    showCategoryName: 'Show Category Name',
    showSeriesLabel: 'Show Series Labels',
    showSubTotals: 'Show Subtotal',
    showSummary: 'Show Summary',
  },
  reportBuilder: {
    selectDataSourcesToCreateReport: 'Select a data source to continue:',
    selectDataSources: 'Data Source',
    createReportTitle: 'Create Report',
    createNewReport: 'Create a new report',
    showSampleValues: 'Show Sample Values',
    hideSampleValues: 'Hide Sample Values',
    reportName: 'Report Name',
    enterNameReport: 'Enter Report Name',
    reportDesc: 'Report Description',
    enterNameDescription: 'Add description for the report (Max of 120 characters)',
    previewTxt: 'This is a preview of limited data set and not the actual report, click on the save button to see the detailed report.',
    previewErrTxt: 'The report preview did not load correctly. Please click on try again to load the preview.',
    previewInPgs: 'Preview in progress',
    previewEnable: 'Please make some changes to enable preview',
    createReport: 'Create Report',
    filterTitle: 'Report Filters',
    filterDescription: 'These Filters will be visible to the users.',
    editRpName: 'Edit report name',
    editRpDesc: 'Edit report description',
    duplicateReportText: '*Report with same name already exists',
    addSuccess: 'The report has been saved successfully.',
    editedSucess: 'The changes have been saved successfully.',
    restrictSalesGroupFilter: 'Modification of sales group filter is not allowed.',
    disableSaveBtn: 'Please enter name and ',
    disableSaveBtn2: 'description to proceed.',
    saveReport: 'Save Report',
    saveNewReport: 'Save New Report As',
    assignTags: 'Assign Tags',
    shareReportToProfile: 'Share Report to Profiles',
    noTagsPresent: 'No tags present under this name.',
    noProfilesPresent: 'No profiles present.',
    failedSave: 'Sorry, something went wrong. Please try again.',
    saveReportNoteMsg: 'Note: This report will be saved in the ‘My Space’ section.',
    pinProfileMsg: 'Click here to pin this report to profile',
    unpinProfileMsg: 'Click here to unpin this report to profile',
    deleteReport: 'Delete Report',
    editReport: 'Edit Report',
    confirmDeleteMsg: 'Deleting this report will prevent other users from accessing it. Also, if its used in a dashboard as a widget, the widget will fail to load.',
    confirmDeleteMsgAdditionalContent: 'You are not the owner of the report; the owner will be notified once you delete it.',
    confirmDeleteMsgSubContent: 'Do you still wish to delete?',
    deletedSuccessfully: 'Report has been deleted successfully.',
    editedSuccessfully: 'Report has been edited successfully.',
    editReportHeader: 'Edit report',
    formatting: 'Formatting',
    formatType: 'Format Type',
    decimal: 'Decimal',
    separator: 'Separator',
    symbol: 'Symbol',
    enterSymbolMsg: 'Enter Symbol',
    symbolInputMaxLength: '*You cannot enter symbol text greater than 3 characters in length',
    symbolInputMinLength: '*Symbol Field cannot be empty',
    dateFormat: 'Date format',
    durationStyle: 'Duration Style',
    searchDataSource: 'Search data source',
    crossEntityValueAlert: 'Cannot add cross-entity values. Only values of similar entities can be grouped in drilldowns.',
    customExpressionAlert: 'This is a calculated values (the expression uses arithmetic operators - +, -, / and *). Drilldown configurations are unavailable for calculated values.',
    drilldownColumn: 'Drilldown Column',
    allValuesConfigured: 'You have configured all available values. Looking to create new configuration? Remove a configured value from an existing set and create a new configuration.',
    calculatedValuesTooltip: `This is a calculated values (the expression uses arithmetic operators - +, -, / and *).
    Drilldown configurations are unavailable for calculated values.`,
    userGroupingDrilldownTooltip: 'Custom measures with user fields are unavailable for drilldown.',
    maxConfigurationLimit: 'Max limit (25) of adding configurations has been reached.',
    newConfiguration: 'New Configuration',
    scheduleReport: 'Schedule Report',
    editScheduleReport: 'Edit Schedule',
    applyScheduleReportEditChanges: 'Apply Changes',
    scheduleReportNote: 'Note: This report will only be scheduled for\n yourself.',
    reportScheduleCreate: 'The report has been scheduled successfully.',
    daySelectionWarning: 'Minimum 1 Day has to be remain selected.',
    scheduleFilterBtnText: 'Filters',
    noDataSourceFound: 'No Data Source Found',
    dataSourceNotFound: 'The data source you are looking was not found.',
    configureButtonDisable: 'Modify the new configuration to create another.',
    fieldsButtonDisable: 'Add a value to define its drilldown columns.',
    measureNameLimit: '*You cannot enter a name greater than {0} characters in length.',
  },
  dashboardBuilder: {
    createNewDashboard: 'Create Dashboard',
    enterNameDashboard: 'Create a new dashboard',
    editDbName: 'Edit dashboard name',
    editDbDesc: 'Edit dashboard description',
    enterNameDescription: 'Add description for the dashboard (Max of 120 characters)',
    addWidget: 'Add Widget',
    editWidget: 'Edit Widget',
    noteToCreateWidget: 'Click on the below button to add a widget for the dashboard and populate the page.',
    watchDemo: 'Watch Demo',
    howToCreateDashboard: 'How to create a dashboard',
    or: 'or',
    dashboardSelectReport: '1. Choose report to base the widget on',
    minCountValidation: 'At least one widget needs to be added in the dashboard to save it.',
    addSuccess: 'The dashboard has been saved successfully.',
    editSuccess: 'The dashboard has been edited successfully.',
    failedSave: 'Sorry, something went wrong. Please try again.',
    maxPanelReached: 'Max number of widgets reached ({0}).',
    newWidgetAdded: 'Widget added successfully.',
    widgetEdited: 'Widget edited successfully.',
    widgetLimit: 'Widget’s Limit',
    widgetsAdded: 'Widgets Added',
    addWidgetOrElement: 'Add Widget or Element',
    createWidgetFromReport: 'Create Widget from Report',
    createCustomWidget: 'Create a custom widget using reports',
    addfromExtSource: 'Add from External Source',
    addExtElm: 'Add an external element to this dashboard',
    addTextWidget: 'Add a Text Widget',
    addTextWidgetDesc: 'Add text as a widget in your dashboard',
    addImgWidget: 'Add a Image Widget',
    addImgWidgetDesc: 'Add image as a widget in your dashboard',
    addBtnWidget: 'Add a Button Widget',
    addBtnWidgetDesc: 'Add button as a widget in your dashboard',
    addDividerWidget: 'Add a Divider Widget',
    addDividerWidgetDesc: 'Add a divider to create section in dashboard',
    previewTxt: 'Click on the ‘Preview’ button to update the changes in the widget.',
    configureVisualization: 'Configure the widget visualisation:',
    enterWidgetName: 'Enter Widget Name',
    selectVisualization: 'Select Visualization',
    selectPagination: 'Select Pagination',
    saveDBNoteMsg: 'Note: This dashboard will be saved in the ‘My Space’ section.',
    saveDBHeaderTitle: 'Save New Dashboard As',
    editDBHeaderTitle: 'Save Dashboard As',
    dashboardName: 'Dashboard Name',
    dashboardDesc: 'Dashboard Description',
    enterDBName: 'Enter Dashboard Name',
    enterDBDescription: 'Add description for the dashboard (Max of 120 characters)',
    pinProfileMsg: 'Click here to pin this dashboard to profile',
    unpinProfileMsg: 'Click here to unpin this dashboard to profile',
    shareDBToProfiles: 'Share Dashboard to Profiles',
    reportSharingWarningMsg: 'The reports used in this dashboard will also be shared with the selected profiles.',
    deleteDashboard: 'Delete dashboard',
    editDashboard: 'Edit dashboard',
    cloneDashboard: 'Clone & Edit',
    confirmDeleteMsg: 'Deleting this dashboard will delete its widgets and will stop it from being shared with other users as well.',
    confirmDeleteMsgAdditionalContent: 'You are not the owner of the dashboard; the owner will be notified once you delete it.',
    deletedSuccessfully: 'Dashboard has been deleted successfully. ',
    disableDeleteOrEditDashboard: 'This dashboard can’t be {0} as you are not the owner of this dashboard.',
    disableCloneDashboard: 'This dashboard can’t be {0} as you are not the owner of this dashboard.',
    enterEmbedURL: 'Add Source Link',
    enterEmbedURLPlaceholder: 'https://mydomain.com/report?accessKey=@{User:\nAccessKey ,}&secretKey=@{User:SecretKey ,}',
    embedUrlMailMergeNote: 'Note: To add authentication, you can use @{User:AccessKey ,} and @{User:SecretKey ,} in your URL',
    disableDeleteOnSharedDashboard: 'This dashboard can’t be deleted as you are not the owner of this dashboard.',
    dashboardCantbeDeleted: 'This dashboard can\'t be deleted as it is a system dashboard.',
    dashboardCantbeEdited: 'This dashboard can\'t be edited as it is a system dashboard.',
    dashboardCantbeCloned: 'This dashboard can\'t be cloned as it is a system dashboard.',
    embedLinkError: 'The link is currently not supported.',
    embedErrorText: 'This domain is currently not supported. Please reach to LeadSquared POC for further assistance.',
    embedMailMergeErrorMessage: 'This mail merge is currently not supported. Please reach to LeadSquared POC for further assistance.',
    noPreviewEmbedContent: 'Widget preview will appear here',
    availableMailMerge: 'Available Mail Merge Fields',
    userFields: 'User Fields',
    mailMergeFields: ' Mail Merge Fields',
    accessKey: 'AccessKey',
    secretKey: 'SecretKey',
    enterValidURL: 'Please enter a valid URL',
    isOptional: '(Optional)',
    embedDisableText: 'Please provide a valid URL to preview or add the widget.',
    selectFilterForWidget: 'Select filter to link widget to',
    filters: 'Filters',
    createFilterMsg: 'Create filters, link to widgets & apply to dashboard',
    createFilterHeading: 'Create a new filter',
    saveApplyFilters: 'Save & Apply Filters',
    addFilter: 'Add Filter',
    creatingNewFilter: 'Creating a new filter',
    enterFilterName: 'Enter filter name',
    enterFilterDetails: '1. Enter the filter details:',
    selectFilterType: 'Select the type of filter',
    linkWidgets: ' Choose the widgets you want to link this filter with',
    linked: 'Linked',
    unappliedFilters: 'Unapplied Filters',
    editing: 'Editing',
    noWidget: 'No Widget Found',
    noWidgetFound: 'The widget you are looking was not found.',
    filterLinkingRestrictionMsg: 'Filter cannot be selected as it has already linked to',
    filterName: 'Filter Name',
    filterType: 'Filter Type',
    dropdownFilterSelectionMsg: 'Selecting a dropdown type filter for the first widget will make it the dropdown source for all other widgets you choose.',
    setAsDataSource: 'Set as primary dropdown source',
    primaryDataSource: 'Primary dropdown source',
    disablePanelDelete: 'This panel cannot be deleted as it is has been set as a primary dropdown source',
    disableWidgetEdit: 'This panel cannot be edited',
    dropdownNumericFilterDisabledMsg: 'Please select a Dropdown numeric Filter to Link',
    dropdownStringDisabledMsg: 'Please select a Dropdown string Filter to Link',
    proceedBtnMsg: 'Please complete the following to proceed to next step',
    filterBtnValidationMsg: 'Add widgets to apply filters to them',
    addFilterBtnValidationMsg: 'Please complete the filter linkage to add this filter',
    filterListEmptyMsg: 'To add new filters, click on the',
    saveApplyBtnMsg: 'Please create a filter to save & apply to the dashboard',
    editFilterRestrictionMsg: 'Filter type cannot be changed while editing a filter',
    addingWidget: 'Adding a new widget',
    scheduleDashboardNote: 'Note: This dashboard will only be scheduled daily for yourself.',
    dashboardScheduleCreate: 'The dashboard has been scheduled successfully.',
    serviceFilterLabel: 'Service Group Filter',
  },
  expressionBuilder: {
    customDimension: 'Custom Grouping',
    customMeasure: 'Custom Values',
    editExp: 'Edit Expression',
    slctAgret: 'Select Aggregator',
    deleteExp: 'Delete Expression',
    expHelpDocumentation: 'Click here to view help documentation for building expressions.',
    enterValidExp: 'Please enter valid expression',
    displayName: 'Display Name',
    enterDisplayName: 'Enter Display Name',
    expEditor: 'Expression Editor',
    addCustomExp: 'Add Custom Expression',
    saveExp: 'Save Expression',
    confirmDeleteMsg: 'This action cannot be undone. Do you still want to delete this custom expression?',
    deleteToastMsgSuccess: 'Expression has been deleted successfully.',
  },
  analytics: {
    customAnalytics: 'Custom Analytics',
    report: 'Reports',
    dashboard: 'Dashboards',
    customReportLimit: 'Custom Report Limit: ',
    customDashboardLimit: 'Custom Dashboard Limit: ',
    customAnalyticsFilter: 'Filters',
    view: 'View',
    delete: 'Delete',
    createdBy: 'Created By',
    productLine: 'Product Line',
    dataset: 'Data Set',
    updateOwner: 'Change Owner',
    continue: 'Yes, continue',
    ownerIsYou: 'Owner (You)',
    updateReportOwnerTitleMessage: 'You can update the owner to your user id. This will give you the rights to edit the report.',
    updateDashboardOwnerTitleMessage: 'You can update the owner to your user id. This will give you the rights to edit the dashboard.',
    reassignmentMessage: 'Reassignment can only be done by the new user.',
    updateReportOwnerConfirmationMessage: 'Do you still wish to continue to update the report owner?',
    updateDashboardOwnerConfirmationMessage: 'Do you still wish to continue to update the dashboard owner?',
    currentOwner: 'Current Owner:',
    lastUpdated: 'Last Updated:',
    updateReportOwnerToYourSelfEditConfirmationMessage: 'Do you still wish to change the ownership to yourself to edit the report?',
    updateDashboardOwnerToYourSelfEditConfirmationMessage: 'Do you still wish to change the ownership to yourself to edit the dashboard?',
    updateReportOwnerToYourSelfSaveConfirmationMessage: 'Do you still wish to change the ownership to yourself to save the report?',
    updateDashboardOwnerToYourSelfSaveConfirmationMessage: 'Do you still wish to change the ownership to yourself to save the dashboard?',
    reportOwnerSuccessfullyEdited: 'The report is ready to be edited as owner has been successfully changed.',
    dashboardOwnerSuccessfullyEdited: 'The dashboard is ready to be edited as owner has been successfully changed.',
    reportSaveDeniedMessage: 'The owner of this report has been changed. Due to that, saving the changes in this report is not possible.',
    dashboardSaveDeniedMessage: 'The owner of this dashboard has been changed. Due to that, saving the changes in this dashboard is not possible.',
    makeSelfOwnerDashboard: 'To save the changes, make yourself the owner of this dashboard.',
    makeSelfOwnerReport: 'To save the changes, make yourself the owner of this report.',
    reportsSelected: 'Reports Selected: ',
    dashboardsSelected: 'Dashboards Selected: ',
    pageLoadErrorMessage: 'Custom Analytics page did not load correctly, please try again or report an issue to support.',
  },
  featureGating: {
    reportModuleBlockedTooltip: 'Your current plan does not have access to create custom reports. Please purchase an Add-On to continue.',
    reportBlockedModalHeader: 'You currently do not have access to the Report Builder, as it is not a part of your current plan.',
    reportBlockedModalSubHeader: 'You can purchase the Report Builder as an',
    reportBlockedModalText: 'to create custom reports.',

    custExpModuleBlockedTooltip: 'Your current plan does not have access to create custom expressions. Please purchase an Add-On to continue.',
    custExpBlockedModalHeader: 'You currently do not have access to the Expression Builder, as it is not a part of your current plan.',
    custExpBlockedModalSubHeader: 'You can purchase the Expression Builder as an',
    custExpBlockedModalText: 'to create custom expressions.',

    dbModuleBlockedTooltip: 'Your current plan does not have access to create custom dashboards. Please purchase an Add-On to continue.',
    dbBlockedModalHeader: 'You currently do not have access to the Dashboard Builder, as it is not a part of your current plan.',
    dbBlockedModalSubHeader: 'You can purchase the Dashboard Builder as an',
    dbBlockedModalText: 'to create custom dashboards.',

    scheduleModuleBlockedTooltip: 'Your current plan does not have access to schedule reports. Please purchase an Add-On to continue.',
    scheduleBlockedModalHeader: 'You currently do not have access to the Report Scheduler, as it is not a part of your current plan.',
    scheduleBlockedModalSubHeader: 'You can purchase the Report Scheduler as an',
    scheduleBlockedModalText: 'to schedule your reports.',

    scheduleToOthersModuleBlockedTooltip: 'Your current plan does not have access to schedule reports for others. Please purchase an Add-On to continue.',
    scheduleToOthersBlockedModalHeader: 'You currently do not have access to schedule a report for others, as it is not a part of your current plan.',
    scheduleToOthersBlockedModalSubHeader: 'You can purchase an',
    scheduleToOthersBlockedModalText: 'to schedule your report for others.',

    custDimModuleBlockedTooltip: 'Your current plan does not have access to create custom dimensions. Please purchase an Add-On to continue.',
    custDimBlockedModalHeader: 'You currently do not have access to the Dimension Builder, as it is not a part of your current plan.',
    custDimBlockedModalSubHeader: 'You can purchase the Dimension Builder as an',
    custDimBlockedModalText: 'to create custom dimensions.',

    custReportLimitTooltip: 'You\'ve reached your report creation limit. Purchase an Add-On or manage your existing reports.',
    custReportLimitHeader: 'It seems you\'ve reached the limit of creating reports under your current plan. Don\'t worry, we\'ve got options for you!',
    custReportLimitSubHeader: 'You can choose to buy an',
    custReportLimitText: 'for additional benefits or manage your existing reports.',

    custDBLimitTooltip: 'You\'ve reached your dashboard creation limit. Purchase an Add-On or manage your existing dashboards.',
    custDBLimitHeader: 'It seems you\'ve reached the limit of creating dashboards under your current plan. Don\'t worry, we\'ve got options for you!',
    custDBLimitSubHeader: 'You can choose to buy an',
    custDBLimitText: 'for additional benefits or manage your existing dashboards.',

    scheduleLimitTooltip: 'You\'ve reached your schedule creation limit. Purchase an Add-On or manage your existing schedules.',
    scheduleLimitHeader: 'It seems you\'ve reached the limit of scheduling reports under your current plan. Don\'t worry, we\'ve got options for you!',
    scheduleLimitSubHeader: 'You can choose to buy an',
    scheduleLimitText: 'for additional benefits or manage your existing schedules.',

    custExpLimitTooltip: 'You\'ve reached your expression creation limit. Purchase an Add-On or manage your existing reports.',
    custExpLimitHeader: 'It seems you\'ve reached the limit of creating expressions under your current plan. Don\'t worry, we\'ve got options for you!',
    custExpLimitSubHeader: 'You can choose to buy an',
    custExpLimitText: 'for additional benefits or manage your existing reports.',

    custDimLimitTooltip: 'You\'ve reached your custom dimension creation limit. Purchase an Add-On or manage your existing reports.',
    custDimLimitHeader: 'It seems you\'ve reached the limit of creating dimensions under your current plan. Don\'t worry, we\'ve got options for you!',
    custDimLimitSubHeader: 'You can choose to buy an',
    custDimLimitText: 'for additional benefits or manage your existing reports.',

    manageReports: 'Manage Reports',
    manageDashboards: 'Manage Dashboards',
    manageSchedules: 'Manage Schedules',

    dashboardModuleRestricted: 'Your current plan does not have access to create dashboards',
    reportModuleRestricted: 'Your current plan does not have access to create reports',

    scheduleAccessDeniedSubHeader: 'Your current plan does not have access to schedule reports.',
    accessDeniedText: 'Please purchase an Add-On to continue.',

    exportModuleBlockedTooltip: 'Your current plan does not have access to export reports. Please purchase an Add-On to continue.',
    exportBlockedModalHeader: 'You currently do not have access to export the reports, as it is not a part of your current plan.',
    exportBlockedModalSubHeader: 'You can purchase exporting as an',
    exportBlockedModalText: 'to export your reports.',

  },
};

export default localizationObjectEnUs;
