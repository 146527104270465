import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IDateRangeTypes } from 'core/constants/date';
import { FilterType } from 'core/constants/filter';
import { DateBinningFormatters } from 'core/constants/formatters';
import { PaginationKey, defaultPageValue } from 'core/constants/pagination';
import { v4 as uuidv4 } from 'uuid';
import { APIRequestStatus } from 'core/constants/redux';
import {
  statusCodes, DimensionMode,
  DimensionStatus, ColumnDataTypes,
  FieldEntitiesType, draggableItemType, ZeroAnchorStatus,
  ColumnModes, JobTypes, jobInfoTextLoad, jobInfoTextMerge, dynamicMeasureDefaultConfig,
} from 'core/constants/report';
import {
  ReportReduxModel, ReportResponseModel, AuthReduxModel, ObjModel, FilterModel,
} from 'core/models';
import { SortingOrderTypes } from 'core/models/report-builder/report-builder';
import { IDimension, IVisualization } from 'core/models/report-redux';
import {
  IColumn, IFilterConfig, IDataRefresh, IJob, DynamicMeasureResponse, IConfigVisualization, IDataRefreshStatus,
} from 'core/models/report-response';
import {
  getDatesIfRelativeRangeIsRestricted, getDatesIfAbsoluteRangeIsRestricted,
} from 'core/utils/date.util';
import FilterFetchUtility from 'core/utils/filter-fetch-utility';
import { hasPivotColumn } from 'core/utils/report-builder.util';
import { buildCustomMeasure, getDataTypeFromCustomColumn, buildMeasureColumn } from 'core/utils/report-expressions';
import {
  columnsToDimensions, reOrderMeasures, isMeasure, rebuildSortState,
  getAppliedMeasures, sortColumns, isMeasureDynamic, isMeasureCustom,
  validateAndAppendCountToName, isMeasureAndApplied, areDimensionsEqual, isPieOrDonutChart,
  getEntityKey,
  getUniqIdentiferForDrilldownCols,
} from 'core/utils/report.util';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import moment from 'moment-timezone';

import { getMappedMetaFields } from 'core/utils/common.utility';
import { reOrderDimensions } from 'redux-v2/report-builder/report-builder-store.utils';
import { IVariables } from 'core/models/store';
import { IChartAttributesKeys } from 'components/feature/Report/ReportSidebar/ChartsVisualization/chart-visualization.type';
import { IReportState, ReportActionTypes } from './report-store.state';

const getUserFilterTypeValue = (item: ReportResponseModel.IFilterConfig, reportConfig: ReportResponseModel.IReportConfig) => {
  let value = null;
  if (item.Type === FilterType.UserMultiSelect) {
    if (reportConfig.FiltersMetadata?.UserDropdownSelectType && item.DefaultValue) {
      value = {
        Type: reportConfig.FiltersMetadata.UserDropdownSelectType,
        UserIds: item.DefaultValue,
      };
    }
  } else {
    value = item.DefaultValue;
  }
  return value;
};

const getFilterConfigItemValue = (item: ReportResponseModel.IFilterConfig) => item.IsDynamic || item.IsAdditional;

// Returns the updated report State on cofig load success
// @state - Report State
// @reportConfig - Loaded Report config request
// @variables - auth store varables to update the filter value
// @status - API status to check deleted report
export const getStateOnReportConfigLoadSuccess = (
  state: IReportState,
  reportConfig: ReportResponseModel.IReportConfig,
  variables: AuthReduxModel.IVariables,
  status: number,
): IReportState => {
  if (status === statusCodes.NoContent) {
    return state;
  }

  const newState = initializeNewState(state, reportConfig, variables);
  newState.accessStatusCode = status;
  newState.reportName = reportConfig?.Details?.Title;
  newState.reportDescription = reportConfig?.Details?.Description;
  let dimensions = columnsToDimensions(reportConfig.Columns) || [];

  newState.allMeasures = reOrderMeasures(reportConfig.Columns.filter((c) => isMeasure(c) && !(c?.Props?.Measure?.IsCustomExpression && c?.Props?.Mode === ColumnModes.NotApplied)));
  newState.allMeasures = handleCustomMeasures(newState.allMeasures);

  dimensions = reOrderDimensions([...dimensions]);
  newState.appliedDimensions = dimensions;
  newState.activeDimensions = dimensions;
  newState.columns = getColumnsForActiveDimensions(reportConfig.Columns, dimensions, newState.allMeasures, newState.dynamicDimensions);

  newState.pivotBinningType = getPivotBinningType(newState.appliedDimensions);

  newState.filterConfig = newState.reportConfig.Filters;
  newState.appliedFilters = getAppliedFilters(reportConfig.Filters, variables, newState.reportConfig);
  newState.activeDimensionsLength = getAppliedDimension(newState.appliedDimensions);

  newState.sort = getSort(reportConfig.DefaultSort, newState.appliedDimensions, newState.columns);
  newState.isMultiSort = newState.sort.length > 1;

  newState.page = reportConfig.DefaultPage;

  newState.Visualization = getInitialVisualization(reportConfig.Visualization, reportConfig?.IsAugmented);

  return newState;
};

// Returns the updated report State after intalizing filters and report config.
// @state - Report State
// @reportConfig - Loaded Report config request
// @variables - auth store varables to update the filter value
const initializeNewState = (state: IReportState, reportConfig: ReportResponseModel.IReportConfig, variables: AuthReduxModel.IVariables): IReportState => {
  const newState = state;
  const fetchFilter = new FilterFetchUtility(variables);
  const newAppliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse> = {};

  if (reportConfig?.Filters && !isEmpty(reportConfig.Filters)) {
    reportConfig.Filters.forEach((item) => {
      item.IsDynamic = getFilterConfigItemValue(item);
      const value = getUserFilterTypeValue(item, reportConfig);
      newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(item, value);
    });
  }
  newState.reportConfig = reportConfig;

  newState.selectedToolbarMenuItem = 0;

  return newState;
};

// Returns the custom measures array if any.
// @measures - measures comming from report config.
const handleCustomMeasures = (measures: ReportResponseModel.IColumn[]): ReportResponseModel.IColumn[] => (isValidArrayAndNotEmpty(measures) ? measures.map((item) => {
  if (item?.Props?.Measure?.IsCustomExpression) {
    const customMeasure = buildCustomMeasure(
      item.Name,
      item.Props.Mode,
      item?.Props?.Measure.Expression,
      uuidv4(),
      item?.Props?.Formatter,
      getDataTypeFromCustomColumn(item?.Props?.Measure.Expression),
      item?.Props?.Measure?.RenderType,
    );
    customMeasure.Props.AllowDrillDown = item.Props.AllowDrillDown;
    return customMeasure;
  }
  return item;
}) : []);

// Returns the pivot binning type if any date field applied in column grouping else set it as undefined.
// @dimensions - dimensions comming from report config columns.
const getPivotBinningType = (dimensions: ReportReduxModel.IDimension[]): string => dimensions.find((dim) => dim?.DimensionMode !== DimensionMode.RowGroup && dim?.Applied !== DimensionStatus.NotApplied
&& dim?.DimensionProp?.DataType === ColumnDataTypes.DateTime)?.DimensionProp?.Props?.BinningType;

// Returns the updated applied filter values coming from repotrconfig.
// @filters - Filter config coming from report config.
// @reportConfig - Loaded Report config request.
// @variables - auth store varables to update the filter value.
const getAppliedFilters = (filters: ReportResponseModel.IFilterConfig[], variables: AuthReduxModel.IVariables, reportConfig:ReportResponseModel.IReportConfig): ObjModel.ObjGeneric<FilterModel.IFilterResponse> => {
  const fetchFilter = new FilterFetchUtility(variables);
  const newAppliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse> = {};

  if (filters && !isEmpty(filters)) {
    filters.forEach((item) => {
      item.IsDynamic = getFilterConfigItemValue(item);
      const value = getUserFilterTypeValue(item, reportConfig);
      newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(item, value);
    });
  }

  return newAppliedFilters;
};

// Returns the sort state based on the report config.
// @defaultSort - defaultSort state coming from report config.
// @appliedDimensions - appliedDimensions columns coming from report config.
// @columns - columns coming from report config.
const getSort = (defaultSort: ReportResponseModel.ISortedField[], appliedDimensions: ReportReduxModel.IDimension[], columns: ReportResponseModel.IColumn[]): ReportResponseModel.ISortedField[] => {
  if (defaultSort && !isEmpty(defaultSort)) {
    if (appliedDimensions && getAppliedDimension(appliedDimensions) > 1) {
      return rebuildSortState(defaultSort, appliedDimensions, getAppliedMeasures(columns), []);
    }
    return defaultSort;
  }
  return [];
};

// Returns the updated report State on filters load request.
// @state - Report State
// @filterId - filterID for which api call request
// @variables - auth store varables to update the filter value
// @isLazyLoad - boolean to check the lazyLoad
export const getStateOnFilterLoadRequest = (
  state: IReportState, filterId: string, variables: AuthReduxModel.IVariables,
  isLazyLoad?: boolean,
) => {
  const newState = state;
  const filtersLoading = cloneDeep(newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]);
  if (isLazyLoad) {
    filtersLoading[filterId] = APIRequestStatus.LazyLoading;
    newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
  } else {
    const fetchFilter = new FilterFetchUtility(variables);
    const appliedFilters = cloneDeep(newState.appliedFilters);
    filtersLoading[filterId] = APIRequestStatus.Processing;
    newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
    if (newState.filterConfig && !isEmpty(newState.filterConfig)) {
      newState.filterConfig.forEach((item) => {
        if (item?.LinkedTo === filterId) {
          if (appliedFilters[item.ID]?.shouldLoad) {
            return;
          }
          appliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(item, null);
        }
      });
    }
    newState.appliedFilters = appliedFilters;
  }
  return newState;
};
// Returns the updated report State on filter load success
// @state - Report State
// @filterId - filterID for which api call request
// @filterRsponse - Filter Respomse and setting in the state
export const getStateOnFilterLoadSuccess = (
  state: IReportState, filterId: string, filterResponse: FilterModel.IFilterResponse,
) => {
  const newState = state;
  const filtersLoading = clone(newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  filtersLoading[filterId] = APIRequestStatus.Success;
  let isUsersModifiedFlag = false;
  Object.values(filtersLoading).forEach((status) => {
    if (status === APIRequestStatus.Processing) isUsersModifiedFlag = true;
  });
  newState.isUsersModified = isUsersModifiedFlag;
  newAppliedFilters[filterId] = filterResponse;
  newState.appliedFilters = newAppliedFilters;
  newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
  return newState;
};
// Returns the updated report State on filter load failure
// @state - Report State
// @filterId - filterID for which api call request
export const getStateOnFilterLoadFailure = (
  state: IReportState, filterId: string,
) => {
  const newState = state;
  const filtersLoading = cloneDeep(newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST]);
  filtersLoading[filterId] = APIRequestStatus.Failure;
  newState.requestProcessing[ReportActionTypes.FILTER_LOAD_REQUEST] = filtersLoading;
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};
// Returns the updated report State on report data load success
// @state - Report State
// @reportData - report Data for the report.
export const getStateOnReportDataLoadSuccess = (
  state: IReportState, reportData: ReportResponseModel.IReportData,
) => {
  const newState = state;
  newState.reportData = reportData;
  newState.columns = getColumnsForActiveDimensions(
    newState.columns, newState.activeDimensions, newState.allMeasures, newState.dynamicDimensions, newState.reportConfig.IsJoined,
  );
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  newState.isResetClicked = false;
  newState.enablesSaveAs = newState.trackUserDrilldown ? newState.enablesSaveAs : !newState.isFirstLoad;
  newState.isFirstLoad = false;
  newState.showOthersInfoPopUp = reportData?.Raw?.HasOthersColumn || false;
  newState.mappedMetaFields = getMappedMetaFields(newState.columns, newState.reportData?.Raw?.Data) || null;
  newState.showReport = true;
  newState.trackUserDrilldown = false;
  return newState;
};

// Returns the updated report State on report data load failure
// @state - Report State
export const getStateOnReportDataLoadFailure = (state: IReportState) => {
  const newState = state;
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Failure;
  newState.isResetClicked = false;
  return newState;
};

// Returns the updated report State on report data load failure
// @state - Report State
export const getStateOnFilterChange = (
  state: IReportState, filterId: string, filterValue: any, variables: AuthReduxModel.IVariables,
  useChangedFilters: boolean = false,
) => {
  const newState = state;
  const fetchFilter = new FilterFetchUtility(variables);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  const selectedFilterConfig = newState.filterConfig.find((item:IFilterConfig) => item.ID === filterId);
  if (newState.filterConfig && !isEmpty(newState.filterConfig)) {
    newState.filterConfig.forEach((item) => {
      if (item.ID === filterId && newAppliedFilters[item.ID]) {
        newAppliedFilters[filterId].FilterResponse = filterValue;
      } else if ((item.LinkedTo && item.LinkedTo === filterId)
                || (selectedFilterConfig?.Entity === FieldEntitiesType.User && item.Type === FilterType.UserMultiSelect)) {
        newAppliedFilters[item.ID] = fetchFilter.GetDefaultSingleFilter(item, null);
        newState.isUsersModified = true;
      }
    });
  }
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = useChangedFilters;
  return newState;
};

// Returns the updated report State on pagination change
// @state - Report State
// @paginationKey - key of pagination object
// @pagination Value - New pagination value
// @isAutodrilldown - boolean to check weather it is auto drilldown or not
export const getStateOnPaginationChange = (
  state: IReportState, paginationKey: string, paginationValue: number,
  isAutodrilldown?: boolean,
) => {
  const newState = state;
  let newPage = clone(newState.page);
  if (isAutodrilldown) {
    newPage = clone(newState.autodrilldownData.page);
  }
  if (paginationKey === PaginationKey.PageSize) {
    newPage.PageIndex = 1;
    newPage.PageSize = paginationValue;
  } else {
    newPage.PageIndex = paginationValue;
  }
  if (isAutodrilldown) {
    newState.autodrilldownData = { ...newState.autodrilldownData, page: newPage };
  } else {
    newState.page = newPage;
  }
  return newState;
};

// Returns the updated report State on sorting change
// @state - Report State
// @columnType - Type of field on which sort is applied
// @order - Order of sort
// @noMultiSort - boolean to check the multiSort
export const getStateOnSortColumn = (
  state: IReportState,
  columnType: draggableItemType,
  columnName: string,
  order: SortingOrderTypes | null, // null implies removal of sort
  multiSort: boolean,
): IReportState => {
  const newState = state;
  const {
    columns, activeDimensions, sort, pivotSort,
  } = newState;
  const [sortState, hasChanged] = sortColumns(
    sort,
    activeDimensions,
    getAppliedMeasures(columns),
    columnType,
    columnName,
    order,
    !newState.reportConfig.IsAugmented && multiSort,
    state?.autodrilldownData?.drilldownFields || [],
    false,
  );
  if (!hasChanged) return state;
  let newPivotSort = pivotSort;
  if (hasPivotColumn(columns)) {
    newPivotSort = sortState?.length ? sortState[0].Direction : pivotSort;
  }
  newState.sort = sortState;
  newState.pivotSort = newPivotSort;
  return newState;
};

// Returns the updated report State on User Drilldown
// @state - Report State
// @filterId - Changed filterId in User Drilldown
// @drilldownValue - drilldown level of the user drilldown level
// @user - clicker user for drilldown
export const getStateOnDrilldownChange = (
  state: IReportState, filterId: string, drilldownValue: any, user: string, varaibles: IVariables, value: any,
) => {
  let newState = state;
  const newAppliedFilters = clone(newState.activeFilters);
  const newPrevAppliedFilters = clone(newState.prevAppliedFilters);
  const newAppliedFilter = clone(newAppliedFilters[filterId]);
  newAppliedFilter.Hidden = newPrevAppliedFilters.length ? newState.drilldownPoint.level : user;
  newState.drilldownPoint = {
    filterId,
    level: drilldownValue,
  };
  newAppliedFilters[filterId] = newAppliedFilter;
  newPrevAppliedFilters.push(newAppliedFilters);
  newState.prevAppliedFilters = newPrevAppliedFilters;
  newState.useChangedFilters = true;
  newState.trackUserDrilldown = true;
  if (newState?.filterConfig && !isEmpty(newState.filterConfig)) {
    const filter = newState.filterConfig.find((item) => item.ID === filterId);
    if (filter) {
      let filterValue = value;
      switch (filter.Type) {
        case FilterType.GroupMultiSelect:
        case FilterType.CustomDefinedMultiSelect:
        case FilterType.LSQMetadataMultiSelect:
        case FilterType.UserMultiSelect:
          if (drilldownValue && !isArray(drilldownValue)) {
            filterValue = [filterValue];
          }
          break;
        default:
          break;
      }
      newState = getStateOnFilterChange(newState, filterId, filterValue, varaibles, true);
    }
  }
  return newState;
};

const handleFilterType = (appliedFilter: any, dynamicFilter: ReportResponseModel.IFilterConfig) => {
  switch (dynamicFilter.Type) {
    case FilterType.LSQMetadataSingleSelect:
    case FilterType.CustomDefinedSingleSelect: {
      const filterValue = (appliedFilter?.FilterOptions[0]?.value);
      appliedFilter.FilterResponse = filterValue;
      break;
    }
    default: {
      const defaultFilter = new FilterFetchUtility(null).GetDefaultSingleFilter(dynamicFilter, null);
      appliedFilter.FilterResponse = defaultFilter?.FilterResponse;
      break;
    }
  }
};

// Returns the updated report State on Reverse User Drilldown
// @state - Report State
// @index - Drilldown level
export const getStateOnReverseDrilldown = (state: IReportState, index: number) => {
  const newState = state;
  const newPrevAppliedFilters = cloneDeep(newState.prevAppliedFilters).slice(0, index + 1);
  const newAppliedFilters = cloneDeep(newPrevAppliedFilters.pop());
  const newFilterConfig = newState.reportConfig.Filters;
  const filterIdsInNewAppliedFilters = Object.keys(newAppliedFilters);
  const dynamicFilterIds = filterIdsInNewAppliedFilters.filter((Id) => !!(newFilterConfig.find((filter) => filter.ID !== Id)));
  const newDynamicFilters: ReportResponseModel.IFilterConfig[] = [];
  if (isValidArrayAndNotEmpty(newState.dynamicFilters)) {
    newState.dynamicFilters.forEach((filter:ReportResponseModel.IFilterConfig) => {
      if (filter.ID && isValidArrayAndNotEmpty(dynamicFilterIds) && dynamicFilterIds.includes(filter.ID)) {
        newFilterConfig.push(filter);
        newDynamicFilters.push(filter);
      }
    });
  }

  if (newDynamicFilters && !isEmpty(newDynamicFilters)) {
    newDynamicFilters.forEach((dynamicFilter:ReportResponseModel.IFilterConfig) => {
      if (!newAppliedFilters[dynamicFilter.ID]) {
        const appliedFilter = newState.appliedFilters[dynamicFilter.ID];
        newAppliedFilters[dynamicFilter.ID] = cloneDeep(appliedFilter);
        if (appliedFilter) {
          handleFilterType(appliedFilter, dynamicFilter);
        }
      }
    });
  }
  newState.appliedFilters = newAppliedFilters;
  newState.prevAppliedFilters = newPrevAppliedFilters;
  newState.drilldownPoint.level = newState.appliedFilters[newState.drilldownPoint.filterId].Hidden;
  newState.page.PageIndex = 1;
  newState.useChangedFilters = true;
  newState.trackUserDrilldown = true;
  return newState;
};
// Returns the updated report State on Apply filters
// @state - Report State
export const getStateOnApplyingFilters = (state: IReportState) => {
  const newState = state;
  let newFilterConfig = cloneDeep(newState.filterConfig);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (newFilterConfig && !isEmpty(newFilterConfig)) {
    newFilterConfig = newFilterConfig.filter((config) => !(config.IsDynamic && config.IsRemoved));
  }
  newState.page.PageIndex = 1;
  newState.filterConfig = newFilterConfig;
  newState.appliedFilters = newAppliedFilters;
  newState.activeFilters = newAppliedFilters;
  newState.useChangedFilters = false;

  // set this intermediate state to show loader in report builder component.
  newState.requestProcessing[ReportActionTypes.REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.AboutToLoad;
  return newState;
};

// Returns the updated report State on toolbar menu item change
// @state - Report State
// @index - clicked action fot the tool bar menu item
export const getStateOnSetToolbarMenuItem = (
  state: IReportState, index: number,
) => {
  const newState = state;
  newState.selectedToolbarMenuItem = index;
  return newState;
};

const getDestinationDimensionStatus = (desDimension: IDimension, dimension: IDimension) => {
  let desDimStatus;
  if (desDimension.Applied === DimensionStatus.Mandatory) {
    desDimStatus = DimensionStatus.Mandatory;
    // and if source dimension is mandatory applied , then it should be applied
  } else if (dimension.Applied === DimensionStatus.Mandatory) {
    desDimStatus = DimensionStatus.Applied;
    // if both are mandatory or for any other case status should be swapped.
  } else {
    desDimStatus = dimension.Applied;
  }
  return desDimStatus;
};

const getZeroAnchor = (desDimension: IDimension) => (desDimension.ZeroAnchor === ZeroAnchorStatus.Disabled
  ? ZeroAnchorStatus.Disabled
  : ZeroAnchorStatus.Enabled);

// Returns the updated report State on dimension order change
// @state - Report State
// @sourceIndex - index of the new dimension
// @status - Dimension applied status
// @dimensionMode - Applied Dimension Mode
// @isSwapEnabled -  boolean to check the weather two dimension swaped
export const getStateOnSetGrouping = (
  state: IReportState,
  sourceIndex: number,
  status: DimensionStatus,
  mode: DimensionMode,
  destinationIndex?: number,
  isSwapEnabled?: boolean,
) => {
  const newState = state;
  let newAppliedDimensions = cloneDeep(newState.appliedDimensions);
  let dimension = newAppliedDimensions[sourceIndex]; // source dimension
  const desDimension = newAppliedDimensions[destinationIndex]; // destination dimension

  // if date-time field is added in column grouping
  if (status === DimensionStatus.Applied && mode === DimensionMode.ColumnGroup && dimension.DimensionProp?.Props.BinningType === 'NoneExpr') {
    dimension = {
      ...dimension,
      DimensionProp: {
        ...dimension.DimensionProp,
        Props: {
          ...dimension.DimensionProp.Props,
          BinningType: DateBinningFormatters.DATE_YEAR,
        },
      },
      Props: {
        ...dimension.Props,
        Dimension: {
          ...dimension.Props.Dimension,
          DimensionProp: {
            ...dimension.Props.Dimension.DimensionProp,
            Props: {
              ...dimension.Props.Dimension.DimensionProp.Props,
              BinningType: DateBinningFormatters.DATE_YEAR,
            },
          },
        },
      },
    };
  }

  // if swapping is there, then before applying changes to source dimension, we should swap its values in
  // destination dimension.
  if (isSwapEnabled) {
    // if destination dimension is mandatory applied , then it should remain mandatory applied only,
    // if swapped to row grouping
    const desDimStatus = getDestinationDimensionStatus(desDimension, dimension);
    if (desDimension) {
      desDimension.Applied = desDimStatus;
      desDimension.ZeroAnchor = getZeroAnchor(desDimension); // zero values un-applied by default
      desDimension.DimensionMode = dimension?.DimensionMode;
    }
  }
  // making state changes to source dimension (applicable for all cases)
  dimension.Applied = status;
  dimension.ZeroAnchor = dimension.ZeroAnchor === ZeroAnchorStatus.Disabled
    ? ZeroAnchorStatus.Disabled
    : ZeroAnchorStatus.Enabled; // zero values un-applied by default
  dimension.DimensionMode = mode;

  // swapping source dimension with destination dimension in case of swapping true
  if (isSwapEnabled) {
    newAppliedDimensions[destinationIndex] = dimension;
    newAppliedDimensions[sourceIndex] = desDimension;
  }

  // change position of dimension to putBeforeIndex only in case of addition (no swapping)
  if (destinationIndex !== undefined && !isSwapEnabled) {
    newAppliedDimensions.splice(sourceIndex, 1);
    newAppliedDimensions.splice(destinationIndex, 0, dimension);
  }

  newAppliedDimensions = reOrderDimensions(newAppliedDimensions);

  // if pivot is enabled , zero anchor of all applied dimension should set to enabled, if zeroAnchor is applied to send
  // zero anchor disabled to backend call
  const hasColumnGroupings = newAppliedDimensions?.some((dim) => (
    dim?.DimensionMode !== DimensionMode.RowGroup && dim?.Applied !== DimensionStatus.NotApplied
  ));
  if (hasColumnGroupings) {
    newAppliedDimensions?.forEach((dim) => {
      if (dim?.ZeroAnchor === ZeroAnchorStatus.Applied) {
        dim.ZeroAnchor = ZeroAnchorStatus.Enabled;
      }
    });
  }
  newState.appliedDimensions = newAppliedDimensions;
  return newState;
};

// Returns the updated report State on set measure
// @state - Report State
// @index - new Added measure index
// @status - applied measure status
export const getStateOnMeasureSetState = (state: IReportState, index: number, status: ColumnModes) => {
  if (isEmpty(state.allMeasures) || index < 0 || index >= state.allMeasures.length) {
    return state;
  }
  const newState = state;
  const newMeasures = cloneDeep(newState.allMeasures);
  const measure = newMeasures[index];
  if (isMeasureDynamic(measure) && !isMeasureCustom(measure)) {
    addDeleteDynamicMeasure(status, newMeasures, index);
  } else if (isMeasureCustom(measure) && status === ColumnModes.NotApplied) {
    newMeasures.splice(index, 1);
  } else {
    measure.Props.Mode = status;
    newMeasures.splice(index, 1);
    newMeasures.push(measure);
  }
  newState.allMeasures = newMeasures;
  return newState;
};

// Returns the updated measure list after deletion of measure
// @newMeasures - applied measure list
// @index - deleting measure index
// @status - applied measure status
const addDeleteDynamicMeasure = (status: ColumnModes, newMeasures: Array<IColumn>, index:number) => {
  const measure = newMeasures[index];
  if (status === ColumnModes.NotApplied) {
    newMeasures.splice(index, 1);
  } else {
    const updatedName = validateAndAppendCountToName(measure, newMeasures);
    const clonedMeasure = {
      ...measure,
      Name: updatedName,
      Props: {
        ...measure.Props,
        Mode: status,
        Measure: {
          ...measure.Props.Measure,
          Name: updatedName,
        },
      },
      BuilderConfig: {
        ...measure.BuilderConfig,
        DisplayName: updatedName,
      },
    };
    newMeasures.push(clonedMeasure);
  }
};

// Returns the updated report State on update measure
// @state - Report State
// @index - updating measure index
// @newMeasure - new updated measure
export const getStateOnMeasureUpdate = (state: IReportState, index: number, newMeasure: IColumn) => {
  if (isEmpty(state.allMeasures) || index < 0 || index >= state.allMeasures.length) return state;
  const newState = state;
  const newAllMeasures = cloneDeep(newState.allMeasures);
  newAllMeasures[index] = newMeasure;
  newState.allMeasures = newAllMeasures;
  return newState;
};
// Returns the updated report State on measure reorder
// @state - Report State
// @sourceIndex - starting index of measure
// @destinationIndex - final index of measure
export const getStateOnMeasureReorder = (state: IReportState, sourceIndex: number, destinationIndex: number) => {
  if (isEmpty(state.allMeasures) || sourceIndex < 0 || sourceIndex >= state.allMeasures.length
      || destinationIndex < 0 || destinationIndex >= state.allMeasures.length) {
    return state;
  }
  const newState = state;
  const newMeasures = cloneDeep(newState.allMeasures);
  const measure = newMeasures.splice(sourceIndex, 1);
  if (measure.length !== 1) return state;
  newMeasures.splice(destinationIndex, 0, measure[0]);

  newState.allMeasures = newMeasures;
  return newState;
};
// Returns the updated report State on zero Anchor change
// @state - Report State
// @index - index of the dimension for which zero anchor is changed
export const getStateOnSetZeroAnchor = (
  state: IReportState, index: number,
) => {
  if (
    state.appliedDimensions[index].Applied === DimensionStatus.NotApplied
    || state.appliedDimensions[index].ZeroAnchor === ZeroAnchorStatus.Disabled
  ) {
    // no change upon setting inapplicable zero anchors
    return state;
  }

  const newState = state;
  const newAppliedDimensions = cloneDeep(newState.appliedDimensions);

  newState.appliedDimensions.forEach((item, dimIndex) => {
    if (newAppliedDimensions[dimIndex].ZeroAnchor !== ZeroAnchorStatus.Disabled) {
      // clear Applied status of Enabled anchors to enforce selecting only one anchor
      newAppliedDimensions[dimIndex].ZeroAnchor = ZeroAnchorStatus.Enabled;
    }
  });
  newAppliedDimensions[index].ZeroAnchor = ZeroAnchorStatus.Applied;
  newState.appliedDimensions = newAppliedDimensions;
  return newState;
};

const updateFilterConfigResponse = (newState: IReportState, newAppliedFilters: any, partitionColumnFilterDateLimit: number) => {
  newState?.filterConfig?.forEach((filter) => {
    if (filter.IsPartitionColumn && !filter?.IsRemoved) {
      const filterID = filter.ID;
      const filterResponse = newAppliedFilters[filterID]?.FilterResponse;
      if (filterResponse?.Type === IDateRangeTypes.Relative) {
        newAppliedFilters[filterID].FilterResponse = getDatesIfRelativeRangeIsRestricted(filterResponse?.Value, partitionColumnFilterDateLimit);
      } else {
        newAppliedFilters[filterID].FilterResponse = getDatesIfAbsoluteRangeIsRestricted(moment(filterResponse?.Value?.From),
          moment(filterResponse?.Value?.To), partitionColumnFilterDateLimit);
      }
    }
  });
};

const getSortableDimensionName = (sortableDimension: IDimension) => (sortableDimension?.Mode !== ColumnModes.Sortable && sortableDimension?.ReferTo ? sortableDimension?.ReferTo : sortableDimension?.Name);

// Returns the updated report State on apply dimensions
// @state - Report State
export const getStateOnApplyDimensions = (
  state: IReportState,
  partitionColumnFilterDateLimit: number,
) => {
  const newState = state;

  // Resetting the pivotBinningType
  newState.pivotBinningType = '';
  const newActiveDimensions = cloneDeep(newState.appliedDimensions);
  const appliedMeasures = getAppliedMeasures(newState.allMeasures);
  const newAppliedFilters = cloneDeep(newState.activeFilters);
  newState.sort = rebuildSortState(newState.sort, newActiveDimensions, appliedMeasures, newState?.autodrilldownData?.drilldownFields || []);

  const pivotColumn = newActiveDimensions.find((dim) => dim.DimensionMode === DimensionMode.ColumnGroup);
  // If there is a pivot column and it has DimensionProp, set pivotBinningType
  if (pivotColumn && pivotColumn.DimensionProp?.DataType === ColumnDataTypes.DateTime && pivotColumn.DimensionProp?.Props) {
    newState.pivotBinningType = pivotColumn.DimensionProp.Props.BinningType;
  }

  // if length of sort array after dimension removal is zero, then adding sorting on 1st measure
  if (newState.sort.length === 0 && appliedMeasures.length > 0) {
    const sortableMeasureName = appliedMeasures.find((measure) => measure.Props.AllowDrillDown)?.Name;
    const sortableDimension: IDimension = newActiveDimensions.find((dim) => dim.Applied === DimensionStatus.Applied && dim.DimensionMode === DimensionMode.RowGroup && (dim.Mode === ColumnModes.Sortable || dim.ReferTo));
    // As for metafields the Mode comes as zero so for that we pick the ReferTo Value as Name
    const sortableDimensionName = getSortableDimensionName(sortableDimension);
    if (sortableMeasureName) {
      newState.sort = [
        {
          Field: sortableMeasureName,
          Direction: SortingOrderTypes.ASC, // keeping it default ascending
        },
      ];
    } else if (sortableDimensionName) {
      newState.sort = [
        {
          Field: sortableDimensionName,
          Direction: SortingOrderTypes.ASC, // keeping it default ascending,
        },
      ];
    } else {
      newState.sort = [];
    }
  }

  newState.activeDimensions = newActiveDimensions;
  // we'll check if string field is applied or not
  const appliedStringField = newActiveDimensions.find((dim) => (dim.DimensionProp?.DataType === ColumnDataTypes.String) && dim?.Applied === DimensionStatus.Applied);
  if (appliedStringField) {
    // will update the response for partition column filter
    updateFilterConfigResponse(newState, newAppliedFilters, partitionColumnFilterDateLimit);
  }
  newState.appliedFilters = newAppliedFilters;
  newState.activeFilters = newAppliedFilters; // apply filters along with applying dimensions if partition column and string field exists
  newState.activeDimensionsLength = getAppliedDimension(newState.appliedDimensions);
  newState.isMultiSort = newState.sort.length > 1;
  // if we're changing the dimensions then HasOthersColumn and mappedMetaFields would depend on the new fetch-report-data call
  if (newState.reportData) {
    newState.reportData.Raw.HasOthersColumn = false;
  }
  newState.mappedMetaFields = null;
  newState.page = { ...newState.page, PageIndex: defaultPageValue.PageIndex };
  return newState;
};

// Returns the updated report State on reorder grid columns
// @state - Report State
// @columns - Array of all the stored columns
// @isAutodrilldown - boolean to check the autodrilldown state
export const getStateOnReorderColumns = (
  state: IReportState, columns: Array<ReportResponseModel.IColumn>,
  isAutodrilldown?: boolean,
) => {
  const newState = state;
  if (isAutodrilldown) {
    newState.autodrilldownData.columns = columns;
    // setting the order of autodrilldownData.drilldownFields when we change the order of columns in the grid directly
    const visibleCols:ReportResponseModel.IColumn[] = columns.filter((dim) => dim?.Props?.Mode !== ColumnModes.Hidden);
    const appliedDrilldownFields:ReportResponseModel.IColumn[] = visibleCols
      ?.map((dim) => newState?.autodrilldownData?.drilldownFields
        ?.find((x) => x?.Name === dim?.Name));
    const unappliedDrilldownFields:ReportResponseModel.IColumn[] = newState?.autodrilldownData?.drilldownFields
      ?.filter((dim) => dim?.Props?.Dimension?.Applied === DimensionStatus.NotApplied);
    newState.autodrilldownData.drilldownFields = appliedDrilldownFields?.concat(unappliedDrilldownFields) || [];
  } else {
    newState.columns = columns;
    // if we remove any measure from values section but has not clicked on apply settings then we need to consider all columns,
    // so getUpdatedMeasure will return the correct state.
    const updatedMeasures = getUpdatedMeasures(newState.allMeasures, columns);
    const allAppliedAndSortedMeasures = reOrderMeasures(updatedMeasures?.filter((mes:IColumn) => isMeasureAndApplied(mes)));
    const allUnappliedMeasures = reOrderMeasures(updatedMeasures?.filter((mes:IColumn) => !isMeasureAndApplied(mes)));
    newState.allMeasures = [...allAppliedAndSortedMeasures, ...allUnappliedMeasures];
  }
  return newState;
};

// Returns the updated report State on toggel zero anchor
// @state - Report State
export const getStateOnToggleZeroAnchor = (
  state: IReportState,
) => {
  const newState = state;
  const newAppliedDimensions = cloneDeep(newState.appliedDimensions);
  newState.appliedDimensions.forEach((item, index) => {
    if (newAppliedDimensions[index].ZeroAnchor !== ZeroAnchorStatus.Disabled) {
      // clear Applied status of Enabled anchors
      newAppliedDimensions[index].ZeroAnchor = ZeroAnchorStatus.Enabled;
    }
  });
  newState.appliedDimensions = newAppliedDimensions;
  newState.zeroValuesEnabled = !newState.zeroValuesEnabled;
  return newState;
};

// Returns the updated report State on doing user filter search
// @state - Report State
// @filterId  - user filter Id
// @searchKey - key on based we filter out the items
export const getStateOnSearchDropdownOptions = (
  state: IReportState, filterId: string, searchKey: string,
) => {
  const newState = state;
  const filter = newState.filterConfig.find((config) => config.ID === filterId);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (filter) {
    newAppliedFilters[filterId].OptionsMeta.SearchKey = searchKey;
    newAppliedFilters[filterId].OptionsMeta.Page.PageIndex = 0;
    newAppliedFilters[filterId].FilterOptions = [];
  }
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  return newState;
};

// Returns the updated report State on scrolling user filter.
// @state - Report State
// @filterId  - user filter Id
export const getStateOnLoadMoreDropdownOptions = (
  state: IReportState, filterId: string,
) => {
  const newState = state;
  const filter = newState.filterConfig.find((config) => config.ID === filterId);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  if (filter) {
    newAppliedFilters[filterId].OptionsMeta.Page.PageIndex += 1;
  }
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  return newState;
};

// Returns the updated report State on Adding the dynamic filter.
// @state - Report State
// @index  - index of newely added filter
export const getStateOnAddDynamicFilter = (
  state: IReportState, index: number,
) => {
  const newState = state;
  let newFilterConfig = cloneDeep(newState.filterConfig);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  const dynamicFilter = cloneDeep(newState.dynamicFilters)[index];
  const userMultiSelectFilterData = newFilterConfig.find((config:ReportResponseModel.IFilterConfig) => config.Type === FilterType.UserMultiSelect);
  newFilterConfig = newFilterConfig.filter((config:ReportResponseModel.IFilterConfig) => (config.ID !== dynamicFilter?.ID));
  newFilterConfig.push(dynamicFilter);
  newAppliedFilters[dynamicFilter.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(dynamicFilter, null);
  if (dynamicFilter?.Entity === FieldEntitiesType.User && userMultiSelectFilterData) {
    newState.isUsersModified = true;
    newAppliedFilters[userMultiSelectFilterData.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(userMultiSelectFilterData, null);
  }
  newState.filterConfig = newFilterConfig;
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  return newState;
};

// Returns the updated report State on removing the dynamic filter.
// @state - Report State
// @filterId  - Id of deleted filter
export const getStateOnDeleteDynamicFilter = (
  state: IReportState, filterId: string,
) => {
  const newState = state;
  const newFilterConfig = cloneDeep(newState.filterConfig);
  const newAppliedFilters = cloneDeep(newState.appliedFilters);
  const index = newFilterConfig.findIndex((config) => config.ID === filterId);
  const userMultiSelectFilterData = newFilterConfig.find((config) => config.Type === FilterType.UserMultiSelect);
  if (index !== -1) {
    newFilterConfig[index].IsRemoved = true;
    if (newFilterConfig[index]?.Entity === FieldEntitiesType.User && userMultiSelectFilterData) {
      newState.isUsersModified = true;
      newAppliedFilters[userMultiSelectFilterData.ID] = new FilterFetchUtility(null).GetDefaultSingleFilter(userMultiSelectFilterData, null);
    }
  }
  delete newAppliedFilters[filterId];
  newState.appliedFilters = newAppliedFilters;
  newState.useChangedFilters = false;
  newState.filterConfig = newFilterConfig;
  return newState;
};

// Returns the updated report State on trigger autodrilldown.
// @state - Report State
export const getStateOnAutoDrilldown = (
  state: IReportState, selectedMeasure: string, selectedRowData: ObjModel.Obj, measureFieldAlias: string,
  rowPinnedClicked: boolean, clickedOnColumnTotal: boolean, clickedOnRowTotal: boolean,
) => {
  const newState = cloneDeep(state);
  if (selectedMeasure) {
    newState.autodrilldownData = {
      selectedMeasure,
      selectedRowData,
      page: {
        PageSize: 25,
        PageIndex: 1,
      },
      columns: [],
      sort: [],
      isMultiSort: false,
      reportData: null,
      drilldownFields: null,
      measureFieldAlias,
      rowPinnedClicked,
      clickedOnColumnTotal,
      clickedOnRowTotal,
    };
  } else {
    newState.autodrilldownData = null;
    newState.autoDrilldownStateID = '';
    newState.autoDrilldownDataV2 = null;
  }

  return newState;
};

// Function is called whenever there is change in dimensions. Update dimension and rearrange columns
// based on current order of dimensions. Rearrange the dimension's referer column next to parent column
// (has dimension and ReferTo key) in columns array.
// param columns: array of columns in report config.
// param activeDimensions: array of active(used to fetch report) dimensions.
// returns: array of columns with updated dimension.
export const getColumnsForActiveDimensions = (
  columns: Array<ReportResponseModel.IColumn>, activeDimensions: Array<ReportReduxModel.IDimension>,
  measures: Array<ReportResponseModel.IColumn>, dynamicDimensions: Array<ReportResponseModel.IColumn>,
  blockDrilldown?: boolean,
): Array<ReportResponseModel.IColumn> => {
  let currColumns = cloneDeep(columns);
  const newColumns = [] as Array<ReportResponseModel.IColumn>;
  activeDimensions.forEach((item) => {
    const column = currColumns.find((col) => areDimensionsEqual(item, col));
    const refererCol = item.ReferTo
      ? currColumns.find((col) => col.Name === item.ReferTo)
      : null;
    if (column) {
      if (column?.BuilderConfig?.IsDynamicField && column?.BuilderConfig?.Alias?.length && item.Applied !== DimensionStatus.Applied) {
        currColumns = currColumns.filter((col) => !areDimensionsEqual(col, column));
        // Filter out the metafields
        currColumns = currColumns.filter((col) => item?.BuilderConfig.IsDynamicField && item.Applied === DimensionStatus.NotApplied && item.ReferTo !== col.Name);
        return;
      }
      const dimension = cloneDeep(column.Props.Dimension);
      dimension.Applied = item.Applied;
      dimension.Orderable = item.Orderable;
      dimension.ZeroAnchor = item.ZeroAnchor;
      dimension.DimensionMode = item.DimensionMode;
      dimension.DimensionProp = item.DimensionProp;
      column.Props.Dimension = dimension;
      column.Props.Mode = item.Mode;
      newColumns.push(column);
      // Filter out the columns which doesn't matches the with the active dimensions
      currColumns = currColumns.filter((col) => !areDimensionsEqual(item, col));
      if (refererCol) {
        newColumns.push(refererCol);
        currColumns = currColumns.filter((col) => col.Name !== item.ReferTo);
      }
    } else if (item.Applied === DimensionStatus.Applied) {
      const dimension = cloneDeep(item);
      dimension.Applied = item.Applied;
      dimension.Orderable = item.Orderable;
      dimension.ZeroAnchor = item.ZeroAnchor;
      dimension.DimensionMode = item.DimensionMode;
      const newColumn = {
        Name: dimension.Name,
        BuilderConfig: dimension.BuilderConfig,
      } as IColumn;
      delete dimension.BuilderConfig;
      newColumn.Props = {
        Dimension: dimension,
        Action: null,
        Converter: null,
        Formatter: null,
        IsMeasure: false,
        Mode: item.Mode,
        ReferTo: item.ReferTo,
        IsMasked: item.IsMasked,
        IsUserDynamicField: item.IsUserDynamicField,
      };
      newColumns.push(newColumn);
      if (item?.BuilderConfig.IsDynamicField && item.ReferTo) {
        const referredDynamicField = dynamicDimensions.find((dim) => dim.Name === item.ReferTo);
        if (referredDynamicField) {
          newColumns.push(referredDynamicField);
        }
      }
    }
  });
  return newColumns.concat(
    measures.filter((m) => isMeasure(m) && (isMeasureAndApplied(m) || isMeasureCustom(m) || !m.Props.Measure.Expression)),
  );
};

// Returns the Applied Dimensions
// @input - Array of all the dimensions
export const getAppliedDimension = (input: ReportReduxModel.IDimension[]) => {
  const appliedvalue = input.filter((item: ReportReduxModel.IDimension) => item.Applied !== DimensionStatus.NotApplied);
  const rowGroupColumns = appliedvalue.filter((item) => item.DimensionMode === DimensionMode.RowGroup);
  return rowGroupColumns.length;
};

// Returns the updated report State on dynamic dimension API success.
// @state - Report State
// @dynamic Dimensions - list of new dimensions
// @selectedMeasure - value to check the drilldown state
export const getDynamicDimensionOnDynamicDimensionLoadSuccess = (
  state: IReportState,
  dynamicDimensions: Array<ReportResponseModel.IColumn>,
  selectedMeasure?: string,
) => {
  if (!selectedMeasure) {
    return handleDynamicDimensionsWithoutSelectedMeasure(state, dynamicDimensions);
  }
  return handleDynamicDimensionsWithSelectedMeasure(state, dynamicDimensions);
};

// Returns the updated report State on dynamic dimension API success in report page flow.
// @state - Report State
// @dynamic Dimensions - list of new dimensions
// @selectedMeasure - value to check the drilldown state
const handleDynamicDimensionsWithoutSelectedMeasure = (
  state: IReportState,
  dynamicDimensions: Array<ReportResponseModel.IColumn>,
) => {
  const newState = state;
  const newDynamicDimensions = cloneDeep(dynamicDimensions);
  let allDimensions = reOrderDimensions(newState.appliedDimensions);

  const castedDimensions = getCastedDimensions(newState, newDynamicDimensions);
  // keep report dimensions at the top for better UX, separate from dynamic dimensions
  allDimensions = [...allDimensions, ...castedDimensions];

  newState.dynamicDimensions = newDynamicDimensions || [];
  newState.appliedDimensions = allDimensions; // no meaning of applied and active dimensions for autodrilldown
  newState.activeDimensions = allDimensions;
  newState.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};

// converts and returns updated  dimensions array based on the list of dimensions.
// @state - Report State
// @dynamic Dimensions - list of new dimensions
const getCastedDimensions = (
  state: IReportState,
  newDynamicDimensions: Array<ReportResponseModel.IColumn>,
) => newDynamicDimensions
  .filter(
    (newDim) => state.appliedDimensions.findIndex(
      (appliedDim: IDimension) => getEntityKey(appliedDim) === getEntityKey(newDim),
    ) === -1,
  )
  .map((dim: IColumn) => ({
    Applied: DimensionStatus.NotApplied,
    Orderable: dim.Props?.Dimension?.Orderable,
    ZeroAnchor: dim.Props?.Dimension?.ZeroAnchor,
    DimensionMode: dim.Props?.Dimension?.DimensionMode,
    BuilderConfig: dim.BuilderConfig,
    Name: dim.Name,
    IsMasked: dim.Props.IsMasked,
    DimensionProp: dim.Props?.Dimension?.DimensionProp,
    Mode: dim.Props?.Mode,
    ReferTo: dim.Props?.ReferTo,
    IsUserDynamicField: dim?.Props?.IsUserDynamicField,
    Props: dim?.Props,
  } as IDimension));

// Returns the updated report State on dynamic dimension API success in drilldown flow for selected measure.
// @state - Report State
// @dynamic Dimensions - list of new dimensions
// @selectedMeasure - value to check the drilldown state
const handleDynamicDimensionsWithSelectedMeasure = (
  state: IReportState,
  dynamicDimensions: Array<ReportResponseModel.IColumn>,
) => {
  const newState = state;
  const newDynamicDimensions = dynamicDimensions || [];

  if (newState?.autodrilldownData) {
    newState.autodrilldownData.drilldownFields = newDynamicDimensions?.filter(
      (dim: ReportResponseModel.IColumn) => dim.Props.Mode !== ColumnModes.Hidden,
    ) || [];
  }

  const visibleCols = getVisibleCols(newState);
  const { appliedDrilldownFields, unappliedDrilldownFields } = getAppliedAndUnappliedDrilldownFields(newState, visibleCols);

  // ordering appliedDrilldownFields according to the visualisation columns from fetch-drilldown-report API
  const orderedAppliedDrilldownFields = getOrderedAppliedDrilldownFields(newState, appliedDrilldownFields);

  // TODO SIERA-3098 - we are not getting all column alias in auto-drilldown-additional fileds, Need to check the data issue which is happening only for few users. Addting quick filter fix
  if (newState?.autodrilldownData) {
    newState.autodrilldownData.drilldownFields = orderedAppliedDrilldownFields?.concat(unappliedDrilldownFields)?.filter((dim: IColumn) => dim !== undefined) || [];
  }

  newState.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};

const getVisibleCols = (state: IReportState) : Set<string> => {
  const visibleCols = new Set<string>();
  state?.autodrilldownData?.columns?.forEach((dim: ReportResponseModel.IColumn) => {
    if (dim.Props.Mode !== ColumnModes.Hidden && dim?.BuilderConfig?.Alias) {
      visibleCols.add(dim.BuilderConfig.Alias);
    }
  });
  return visibleCols;
};

const getAppliedAndUnappliedDrilldownFields = (
  state: IReportState,
  visibleCols: Set<string>,
) => {
  const appliedDrilldownFields: ReportResponseModel.IColumn[] = [];
  const unappliedDrilldownFields: ReportResponseModel.IColumn[] = [];
  if (!isValidArrayAndNotEmpty(state?.autodrilldownData?.drilldownFields)) {
    return { appliedDrilldownFields, unappliedDrilldownFields };
  }

  state?.autodrilldownData?.drilldownFields?.forEach((dim) => {
    const newDim = cloneDeep(dim);
    newDim.Props.isDrilldownColumn = true;
    if (dim?.Props?.Dimension) {
      if (visibleCols.has(dim?.BuilderConfig?.Alias)) {
        newDim.Props.Dimension.Applied = DimensionStatus.Applied;
        const updatedDim = updateVisibleColumnProperties(newDim, state);
        appliedDrilldownFields.push(updatedDim);
      } else {
        newDim.Props.Dimension.Applied = DimensionStatus.NotApplied;
        unappliedDrilldownFields.push(newDim);
      }
    }
  });

  return { appliedDrilldownFields, unappliedDrilldownFields };
};
// finds and Returns the column with updated properties.
// @state - Report State
// @newDim - column
const updateVisibleColumnProperties = (
  newDim: ReportResponseModel.IColumn,
  state: IReportState,
) => {
  const visibleColumn = findVisibleColumn(state, newDim);
  if (visibleColumn) {
    newDim.Name = visibleColumn.Name;
    newDim.Props.Formatter = visibleColumn?.Props?.Formatter || null;
    newDim.BuilderConfig.DisplayName = visibleColumn.BuilderConfig.DisplayName;
    newDim.BuilderConfig.IsDynamicField = visibleColumn.BuilderConfig.IsDynamicField;
  }
  return newDim;
};

const findVisibleColumn = (
  state: IReportState,
  dim: ReportResponseModel.IColumn,
): ReportResponseModel.IColumn | undefined => {
  if (!isValidArrayAndNotEmpty(state?.autodrilldownData?.columns)) {
    return undefined;
  }
  return state.autodrilldownData.columns.find(
    (col: IColumn) => getUniqIdentiferForDrilldownCols(col) === getUniqIdentiferForDrilldownCols(dim),
  );
};

const getOrderedAppliedDrilldownFields = (
  state: IReportState,
  appliedDrilldownFields: IColumn[],
) => {
  const orderedAppliedDrilldownFields: IColumn[] = [];
  if (isValidArrayAndNotEmpty(state.autodrilldownData?.columns)) {
    state.autodrilldownData?.columns?.forEach((element) => element?.Props?.Mode !== ColumnModes.Hidden
    && orderedAppliedDrilldownFields.push(
      appliedDrilldownFields.find((field) => field?.BuilderConfig?.Alias === element?.BuilderConfig?.Alias),
    ));
  }
  return orderedAppliedDrilldownFields;
};

// Returns the updated report State on autodrilldown API success.
// @state - Report State
// @reportData - autodrilldown report data
export const getStateOnAutodrilldownReportDataLoadSuccess = (
  state: IReportState, reportData: ReportResponseModel.IReportData,
) => {
  const newState = state;
  const newAutodrilldownData = newState.autodrilldownData;
  newState.requestProcessing[ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST] = APIRequestStatus.Success;
  if (!isEmpty(reportData)) {
    newState.selectedToolbarMenuItem = -1;
    newAutodrilldownData.columns = reportData.Visualization?.Columns.map((col) => { col.Props.isDrilldownColumn = true; return col; });
    newAutodrilldownData.sort = reportData.Visualization?.DefaultSort;
    newAutodrilldownData.isMultiSort = reportData.Visualization?.DefaultSort?.length > 1;
    newAutodrilldownData.reportData = reportData;
    newAutodrilldownData.page.PageSize = reportData.Visualization?.DefaultPage?.PageSize ? reportData.Visualization.DefaultPage.PageSize : defaultPageValue.PageSize;
    newState.autodrilldownData = newAutodrilldownData;
  }
  return newState;
};
// Returns the updated report State on redirected autodrilldown API success.
// @state - Report State
// @reportData - autodrilldown report data
export const getStateOnAutoDrilldownV2ReportDataLoadSuccess = (state: IReportState, data: ReportReduxModel.IAutoDrilldownV2Data) => {
  const newState = state;
  newState.autoDrilldownDataV2 = data;
  newState.autoDrilldownDataV2.DrilldownColumns = data?.DrilldownColumns?.map((col) => { col.Props.isDrilldownColumn = true; return col; });
  newState.showReport = true;
  return newState;
};

// Returns the updated report State on dimension binning change
// @state - Report State
// @index - index of the dimension where the binning is changed
// @value - value of new binning
export const getStateOnSetBinning = (state: IReportState, index: any, value: string) => {
  const newState = state;
  const newAppliedDimensions = cloneDeep(newState.appliedDimensions);
  const newAppliedDimension = newAppliedDimensions[index];
  newAppliedDimension.DimensionProp.Props.BinningType = value;
  newAppliedDimension.Props.Dimension.DimensionProp.Props.BinningType = value;
  if (newAppliedDimension.ZeroAnchor !== ZeroAnchorStatus.Disabled) {
    // unapply zero values for all binning types except Hour of the Day(backend is not compatible)
    if (newAppliedDimension.DimensionProp && newAppliedDimension.DimensionProp?.Props?.BinningType !== DateBinningFormatters.HOUROFTHEDAY) {
      newAppliedDimension.ZeroAnchor = ZeroAnchorStatus.Enabled;
    }
  }
  newAppliedDimensions[index] = newAppliedDimension;
  newState.appliedDimensions = newAppliedDimensions;
  return newState;
};

// Returns the updated report State on drilldown dimension change
// @state - Report State
// @sourceIndex - index of the new dimension
// @status - Dimension applied status
// @dimensionMode - Applied Dimension Mode
// @isSwapEnabled -  boolean to check the weather two dimension swaped
export const getStateOnSetGroupingDrilldown = (
  state: IReportState,
  sourceIndex: number,
  status: DimensionStatus,
  destinationIndex?: number,
  isSwapEnabled?: boolean,
) => {
  const newState = state;
  let newDrilldownFields = cloneDeep(newState.autodrilldownData.drilldownFields);
  const srcDimension = newDrilldownFields[sourceIndex]; // source dimension
  const desDimension = newDrilldownFields[destinationIndex]; // destination dimension
  srcDimension.Props.Dimension.Applied = status;
  if (isSwapEnabled) {
    newDrilldownFields[destinationIndex] = srcDimension;
    newDrilldownFields[sourceIndex] = desDimension;
  }

  // in case of addition
  if (destinationIndex !== undefined && !isSwapEnabled) {
    newDrilldownFields = newDrilldownFields.filter((dim) => !areDimensionsEqual(dim, srcDimension));
    newDrilldownFields.splice(destinationIndex, 0, srcDimension);
  }

  // in case of removal just reorder
  newDrilldownFields = reorderDrilldownFields(newDrilldownFields);
  newState.autodrilldownData.drilldownFields = newDrilldownFields || [];
  newState.autodrilldownData.sort = rebuildSortState(newState.autodrilldownData.sort, newState.activeDimensions, getAppliedMeasures(newState.columns), newState?.autodrilldownData?.drilldownFields, newState?.autodrilldownData?.sort?.length <= 1, true);
  return newState;
};

// Returns the updated drilldown field order on reorder drilldown dimension
// @drilldownFields - updated drilldown fields
export const reorderDrilldownFields = (drilldownFields: IColumn[]): IColumn[] => {
  const groupedDimensions = drilldownFields.filter((dim) => (
    dim.Props.Dimension.Applied === DimensionStatus.Applied
  ));
  const availableDrilldownFields = drilldownFields?.filter((dim) => dim.Props.Dimension.Applied === DimensionStatus.NotApplied) || [];
  return [...groupedDimensions, ...availableDrilldownFields];
};

// Returns the updated report state on date Refresh success
// @jobDetails - jobinfo and status
// @ispolling - boolean to check weather the polling is required
export const dataRefreshDetailsLoadSuccess = (state: IReportState, jobDetails: IDataRefresh, isPolling?: boolean) => {
  const newState = state;
  const newJobObject = newState.jobObject;
  const newJobsArray = [] as IJob[];
  const jobTypes = Object.keys(jobDetails) as JobTypes[];

  jobTypes.forEach((jobType: JobTypes, index) => {
    const today = moment();
    const dataRefreshed = jobDetails[jobType].LastRefreshed;
    const newRefreshTime = today.subtract(dataRefreshed, 'minutes').toDate();
    const prevDataRefreshTime = state?.jobObject?.jobsArray[index]?.Details?.LastRefreshed || dataRefreshed;
    const isRefreshRequiredAlreadySet = state?.jobObject?.jobsArray[index]?.isRefreshReq || false;

    const job: IJob = {
      type: jobType,
      Details: {
        LastRefreshTime: newRefreshTime,
        //  we are multiplying refresh interval by 3 for load job but we will start polling on actual interval -SIERA-2802
        NextRefreshInterval: jobType === JobTypes.Load ? jobDetails[jobType].NextRefreshInterval * 3 : jobDetails[jobType].NextRefreshInterval,
        LastRefreshed: jobDetails[jobType].LastRefreshed,
      },
      InfoText: jobType === JobTypes.Load ? jobInfoTextLoad : jobInfoTextMerge,
      // prevDataRefreshTime is Greater than new one dataRefreshed, which means job has ran and we have a updated data
      isRefreshReq: isPolling ? (isRefreshRequiredAlreadySet || prevDataRefreshTime > dataRefreshed) : false,
    };
    newJobsArray.push(job);
    if (jobTypes.length === 1 && jobTypes.length - 1 === index) {
      const extraJob = clone(job);
      extraJob.type = JobTypes.Merge;
      extraJob.InfoText = jobInfoTextMerge;
      newJobsArray.push(extraJob);
    }
  });
  newJobObject.headerName = 'Data Update';
  newJobObject.jobsArray = newJobsArray;
  newState.jobObject = newJobObject;
  newState.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};
// Returns the updated report state on date Refresh status success
// @jobDetails - jobinfo and status
// @ispolling - boolean to check weather the polling is required
export const dataRefreshStatusLoadSuccess = (state: IReportState, jobDetails: IDataRefreshStatus, isPolling?: boolean) => {
  const newState = state;
  const newJobObject = newState.jobObject;
  const newJobsArray = [] as IJob[];
  const namespaceTypes = Object.keys(jobDetails) as FieldEntitiesType[];

  namespaceTypes.forEach((namespace: FieldEntitiesType, index) => {
    const today = moment();
    const dataRefreshed = jobDetails[namespace].LastRefreshed;
    const newRefreshTime = today.subtract(dataRefreshed, 'minutes').toDate();
    const prevDataRefreshTime = state?.jobObject?.jobsArray[index]?.Details?.LastRefreshed || dataRefreshed;
    const isRefreshRequiredAlreadySet = state?.jobObject?.jobsArray[index]?.isRefreshReq || false;

    const job: IJob = {
      type: namespace,
      Details: {
        LastRefreshTime: newRefreshTime,
        //  we are multiplying refresh interval by 3 for load job but we will start polling on actual interval -SIERA-2802
        NextRefreshInterval: jobDetails[namespace].NextRefreshInterval * 3,
        LastRefreshed: jobDetails[namespace].LastRefreshed,
      },
      InfoText: namespace,
      // prevDataRefreshTime is Greater than new one dataRefreshed, which means job has ran and we have a updated data
      isRefreshReq: isPolling ? (isRefreshRequiredAlreadySet || prevDataRefreshTime > dataRefreshed) : false,
    };
    newJobsArray.push(job);
  });
  newJobObject.headerName = 'Data Update';
  newJobObject.jobsArray = newJobsArray;
  newState.jobObject = newJobObject;
  newState.requestProcessing[ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};
// Returns the updated report state on dynamic measure Api success
// @state - Report State
// @dynamicMeasures - New Dynamic Measures
export const updateDynmaicMeasureStateOnSuccess = (state: IReportState, dynamicMeasures: Array<DynamicMeasureResponse>) => {
  const newState = state;
  newState.isDynamicMeasuresLoaded = dynamicMeasures.length > 0;
  const updatedDynamicMeasures = dynamicMeasures.map(({
    DisplayName, Alias, DataType, Entity, SchemaName, JoinID,
  }: DynamicMeasureResponse) => buildMeasureColumn(DisplayName, Alias, DataType, 'Sum', dynamicMeasureDefaultConfig, Entity, SchemaName, JoinID));
  newState.allMeasures = [...newState.allMeasures, ...updatedDynamicMeasures];
  newState.requestProcessing[ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST] = APIRequestStatus.Success;
  return newState;
};

// Returns the updated measures based on the columns
// @measures -  measures list
// @columns -  Array of all the columns based.
export const getUpdatedMeasures = (measures: IColumn[], columns: IColumn[]) => {
  const newAllMeasuresWithoutApplied = measures?.filter((measure) => !isMeasureAndApplied(measure));
  const newAllMeasuresWithoutAppliedAndPreDefined = newAllMeasuresWithoutApplied.filter((m) => isMeasureDynamic(m));
  const measuresFromColumns = columns?.filter((col) => isMeasure(col)
    && !newAllMeasuresWithoutAppliedAndPreDefined.some((m) => m.Name === col.Name));
  return newAllMeasuresWithoutAppliedAndPreDefined.concat(measuresFromColumns);
};

// Returns the Initial Visualization state for the charts.
export const getInitialVisualization = (configVisualization: IConfigVisualization, isAugumented: boolean):IVisualization => {
  const { Type, Builder } = configVisualization;

  // default property from config
  if (Builder?.Properties) {
    return {
      type: Type,
      attributes: {
        showDataLabels: Builder?.Properties?.showDataLabels,
        fullStacked: Builder?.Properties?.fullStacked,
        showPercentage: Builder?.Properties?.showPercentage,
        stacked: Builder?.Properties?.stacked,
        showSharedTooltip: Builder?.Properties?.showSharedTooltip,
        hideLegend: Builder?.Properties?.hideLegend,
        showCategoryName: Builder?.Properties?.showCategoryName,
        showSeriesLabel: Builder?.Properties?.showSeriesLabel,
        showSubTotals: Builder?.Properties?.showSubTotals,
        showSummary: !(IChartAttributesKeys.showSummary in Builder?.Properties) && !isAugumented ? true : Builder?.Properties?.showSummary,
      },
    };
  }

  const isPieOrDonut = isPieOrDonutChart(Type);
  return {
    type: Type,
    attributes: {
      showDataLabels: !(Builder?.Properties?.HideDataLabels ?? true),
      showPercentage: isPieOrDonut ? !(Builder?.Properties?.HideDataLabels ?? true) : false,
      fullStacked: isPieOrDonut ? false : Builder?.Properties?.StackedStyle?.toLowerCase() === 'percent',
      stacked: isPieOrDonut ? false : Builder?.Properties?.PlotStyle?.toLowerCase() === 'stacked',
      showSubTotals: Builder?.Properties?.showSubTotals,
      showSummary: (!Builder?.Properties || !(Builder?.Properties && IChartAttributesKeys.showSummary in Builder?.Properties)) && !isAugumented ? true : Builder?.Properties?.showSummary,
    },
  };
};

// Return the updated prop for the measure to store in the config
// @column - Measure for which we need to update the measure
export const removeBuilderPropForMeasure = (column:IColumn):IColumn => {
  if (column?.Props?.Measure?.IsMeasure) {
    return {
      Name: column.Name,
      Props: column?.Props,
      AllowDrillDown: column?.AllowDrillDown,
    };
  }
  return column;
};

// Returns the updated report State on drilldown sorting change
// @state - Report State
// @columnType - Type of field on which sort is applied
// @order - Order of sort
// @noMultiSort - boolean to check the multiSort
export const getStateOnDrilldownSortColumn = (
  state: IReportState,
  columnType: draggableItemType,
  columnName: string,
  order: SortingOrderTypes | null, // null implies removal of sort
  multiSort: boolean,
): IReportState => {
  const newState = state;
  const {
    columns, activeDimensions, autodrilldownData,
  } = newState;
  const drilldownFields = autodrilldownData?.drilldownFields;
  const [sortState, hasChanged] = sortColumns(
    autodrilldownData.sort || [],
    activeDimensions,
    getAppliedMeasures(columns),
    columnType,
    columnName,
    order,
    !state.reportConfig.IsAugmented && multiSort,
    drilldownFields,
    true,
  );
  if (!hasChanged) return newState;
  return {
    ...newState,
    autodrilldownData: {
      ...newState.autodrilldownData,
      sort: sortState,
    },
  };
};
