/* eslint-disable no-shadow */

import { ChartAttributesKey, VisualizationLabels, VisualizationTypes } from 'core/constants/visualizations';
import {
  AreaChart, BarChart, ColumnChart, DonutChart, LineChart, PieChart,
} from 'components/common/svg';
import { Summary } from 'core/constants/report';
import { IProfileItem } from 'core/models/profile';
import { FilterType } from 'core/constants/filter';
import { ChartMenuVisualization } from '../ChartsVisualization/charts-visualization.component';

export enum DroppableTypes {
  Dimensions = 'Dimensions',
  Measures = 'Measures',
}

// IDs used by <Droppable /> for identifying each droppable section.
export enum DroppableIds {
  AvailableFields = 'AvailableFields',
  RowGrouping = 'RowGrouping',
  ColumnGrouping = 'ColumnGrouping',
  DrilldownGrouping = 'DrilldownGrouping',
  Measures = 'Measures',
}

export const GroupingDragDropPlaceholder = 'Add fields for grouping';
export const dimensionItemHeightPx = 32;
export const dimensionItemBottomMarginPx = 8; // TODO: Use these values in respective CSS.
export const autodrilldownBreadcrumbsTooltip = 'Click here to go back';
export const mouseEnterDelay = 0.5;
export const SaveChanges = 'Save Changes';
export const Cancel = 'Cancel';
export const ValueAlreadyExists = '*A value with this name already exists';
export const WrongDisplayName = '*You have entered an invalid character. Please enter a valid name.';
export const SystemDefaultDashboardId = 'MarketingLeadsDashboardFinal';
export const toastTimer = 5000;

export const restrictedCharsForInput = ['`', '\'', '"', '\\', ';', '.', '&', '>', '<'];

export const maxCharLimit = 256;

export const triggerPreviewAndAddWidget = 100000;

export enum KeyCodes {
  Backspace = 8,
  Spacebar = 32,
}

export const NoDescription = 'No Description';

export const FailedToRedirectToReport = 'Failed to redirect to the report. Please try again.';

export const chartMenuVisualization: ChartMenuVisualization[] = [
  {
    type: VisualizationTypes.Table,
    icon: null,
    label: VisualizationLabels.NoChart,
    attributesKey: ChartAttributesKey.Table,
  },
  {
    type: VisualizationTypes.BarChart,
    icon: BarChart,
    label: VisualizationLabels.Bar,
    attributesKey: ChartAttributesKey.Column,
  },
  {
    type: VisualizationTypes.ColumnChart,
    icon: ColumnChart,
    label: VisualizationLabels.Column,
    attributesKey: ChartAttributesKey.Column,
  },
  {
    type: VisualizationTypes.LineChart,
    icon: LineChart,
    label: VisualizationLabels.Line,
    attributesKey: ChartAttributesKey.Line,
  },
  {
    type: VisualizationTypes.AreaChart,
    icon: AreaChart,
    label: VisualizationLabels.Area,
    attributesKey: ChartAttributesKey.Line,
  },
  {
    type: VisualizationTypes.PieChart,
    icon: PieChart,
    label: VisualizationLabels.Pie,
    attributesKey: ChartAttributesKey.Pie,
  },
  {
    type: VisualizationTypes.DonutChart,
    icon: DonutChart,
    label: VisualizationLabels.Donut,
    attributesKey: ChartAttributesKey.Pie,
  },
];

export const pivotKeysValuesForSummaryCol:string[] = [Summary, '{Summary}<SIERA_INTERNAL_REPLACE>{Summary}'];

export const MySpace : IProfileItem = {
  Id: 'MySpace',
  Name: 'My Space',
};

export const DefaultProfile : IProfileItem = {
  Id: 'Default',
  Name: 'Default Profile',
};

export const defaultPreviewPaging = {
  PageIndex: 1,
  PageSize: 10,
};

export interface DefaultFilterTypes {
  [key: string]: Object
}

export const defaultFilterObject = {
  Type: FilterType.EmptyFilterType,
  Label: '',
  IsPartitionColumn: false,
  linkedPanelFilter: {},
  ID: '',
  DefaultValue: {},
  Metadata: {},
  IsApplied: false,
  IsEditing: false,
  hide: false,
};

export const drawerSize = {
  small: 388,
  medium: 440,
  large: 972,
};

export enum breakpoints {
  MOBILE = 430,
  TABLET= 1024,
}

export const allowedInputValueRegEx = /^[a-zA-Z0-9,.-]{0,50}$/;

export const specialCharRegEx = /^[\p{L}\p{M}\d,\-.\s]{0,50}$/g;
