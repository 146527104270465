import {
  BinningZeroValue,
  ColumnActionTypes,
  ColumnDataTypes,
  ColumnModes,
  DimensionMode,
  DimensionNoValue,
  DimensionStatus,
  DrilldownChangesTypes,
  DrilldownChangeTypes,
  EncryptedText,
  FieldEntitiesType,
  GroupedSubTotalsSeperator,
  PivotColGrpHeaderSeparator,
  SubTotalsSeperator,
  ZeroAnchorStatus,
} from 'core/constants/report';
import {
  FilterModel, GridModel, ObjModel, ReportReduxModel, ReportResponseModel,
} from 'core/models';
import {
  IColumn, IColumnActionDrilldownChangeMeta, IColumnProperties, IColumnFormatter,
} from 'core/models/report-response';
import { Obj, ObjectGeneric, ObjGeneric } from 'core/models/obj';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { SelectedRow } from 'services/report.service';
import { Column, RowNode } from 'ag-grid-enterprise';
import { DateBinningFormatters, Formatters } from 'core/constants/formatters';
import {
  DraggableItemBorderColor,
  IDraggableItemProps,
} from 'components/common/DraggableItem/draggable-item.interface';
import { displayName, isValidArrayAndNotEmpty, isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IDimension } from 'core/models/report-redux';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/pendoClassNames';
import { drilldownColumnDelimiter } from 'core/constants/common';
import {
  areDimensionsEqual,
  getEntityKey,
  getFieldIdentifier,
  getTextTooltipContentForDimension,
  getTextTooltipContentForMeasure,
  isDimensionApplied,
  isMeasure,
  isMeasureAndApplied,
} from './report.util';
import { clearPrefix } from './common.utility';

const getDrilldownVal = (prev: number, metadata: ReportResponseModel.IColumnActionDrilldownMeta, prevAppliedFilters: Array<ObjModel.ObjGeneric<FilterModel.IFilterResponse>>) => {
  let result = '';
  if (isValidArrayAndNotEmpty(metadata.DrilldownChanges.Changes)) {
    metadata.DrilldownChanges.Changes.forEach((change: ReportResponseModel.IColumnActionDrilldownChange) => {
      if (change.Type === DrilldownChangeTypes.Filter) {
        const changeMeta = change.ChangeMeta as IColumnActionDrilldownChangeMeta;
        if (changeMeta) {
          const filterToChange = changeMeta.FilterID;
          const prevValue = prevAppliedFilters[prev][filterToChange];
          if (prevValue && prevValue.Hidden && typeof prevValue.Hidden === 'string'
                  && prevValue.Hidden.length) {
            result = prevValue.Hidden;
          }
        }
      }
    });
  }
  return result;
};

export const getDrilldownValue = (
  prev: number, isParent: boolean, prevAppliedFilters: Array<ObjModel.ObjGeneric<FilterModel.IFilterResponse>>,
  gridColumns: Array<GridModel.IColumnConfig>,
): string => {
  if (!isParent && prevAppliedFilters[prev] && gridColumns?.length) {
    const drilldownIndex = gridColumns.findIndex(
      (c) => c?.action?.Type === ColumnActionTypes.Drilldown,
    );
    if (drilldownIndex > -1) {
      const column = gridColumns[drilldownIndex];
      const metadata = column.action.Meta as ReportResponseModel.IColumnActionDrilldownMeta;
      if (metadata?.DrilldownChanges?.Changes && metadata?.DrilldownChanges?.Type === DrilldownChangesTypes.FilterOnly) {
        const result = getDrilldownVal(prev, metadata, prevAppliedFilters);
        if (result) {
          return result;
        }
      }
    }
  }
  return 'Back';
};

const isColumnHidden = (Props: IColumnProperties, isAutodrilldown: boolean, column: ReportResponseModel.IColumn) => !!(Props && Props.Mode === ColumnModes.Hidden) || (Props && !isEmpty(Props.ReferTo)) || (!isAutodrilldown && !isMeasureAndApplied(column));

export const getColumnsProperties = (columns: Array<ReportResponseModel.IColumn>, isAutodrilldown?: boolean, allowAutodrilldown?: boolean, isDashboard?: boolean)
  : Array<GridModel.IColumnConfig> => {
  const colsDefs = [] as Array<GridModel.IColumnConfig>;
  const refererCols = {} as ObjGeneric<string>; // stores currentColumn to ReferredColumn link
  const dimensionMap = prepareDimensionList(columns);
  const hasPivot = hasPivotColumn(columns);
  columns.forEach((column) => {
    const { Props, BuilderConfig } = column;
    let { Name } = column;
    if (BuilderConfig?.IsDynamicField) {
      Name = getColumnName(column, isAutodrilldown);
    }
    // if a column is hidded or if it contains a ReferTo to property then set hide to true
    let hide = isColumnHidden(Props, isAutodrilldown, column);
    let rowGroup = false;
    let suppressMovable = false;
    let isPivotColumn = false;
    let isValueColumn = false;
    let parentDataColumn = '';
    let binningType = '';
    let headerName = Name;
    let autodrilldownHeader = Name;
    let aggFunc = null;
    if (!isAutodrilldown) {
      // is dimension
      if (Props?.Dimension) {
        if (Props.Mode !== ColumnModes.Hidden) {
          hide = true;
          // if dimension is applied
          if (Props.Dimension.Applied !== DimensionStatus.NotApplied) {
            isPivotColumn = column.Props.Dimension.DimensionMode !== DimensionMode.RowGroup;
            rowGroup = column.Props.Dimension.DimensionMode !== DimensionMode.ColumnGroup;
          }
        }
        suppressMovable = true;
        if (Props.ReferTo) {
          refererCols[Props.ReferTo] = Name;
        }
        if (Props?.Dimension?.DimensionProp?.DataType === ColumnDataTypes.DateTime && Props.Dimension.DimensionProp?.Props?.BinningType) {
          binningType = Props.Dimension.DimensionProp.Props.BinningType;
        }
      } else if (hasPivot && !dimensionMap[column.Name] && !isPivotColumn && !rowGroup) {
        isValueColumn = isMeasureAndApplied(column);
        // Setting value as first for string type measure values
        aggFunc = column.Props.Formatter.FormatterType === Formatters.DATE
                  || column.Props.Formatter.FormatterType === Formatters.DURATION
                  || column.Props.Formatter.FormatterType === Formatters.STRING ? 'first' : 'sum';
      }
      if (Name in refererCols) {
        parentDataColumn = refererCols[Name];
        const index = columns.findIndex((item) => (item.Props && item.Props.Dimension
        && (item.Props.ReferTo === Name)));
        if (index > -1) {
          suppressMovable = true;
          hide = true;
          const referredDimensionProps = columns[index].Props;
          if (referredDimensionProps && referredDimensionProps.Dimension) {
            if (referredDimensionProps.Dimension.Applied !== DimensionStatus.NotApplied) {
              const dimension = referredDimensionProps.Dimension;
              isPivotColumn = dimension.DimensionMode !== DimensionMode.RowGroup;
              rowGroup = dimension.DimensionMode !== DimensionMode.ColumnGroup;
            }
          }
        }
      }
    }
    if (isAutodrilldown) {
      autodrilldownHeader = clearPrefix(headerName, drilldownColumnDelimiter);
    }
    if (column.Props.ParentDimension) {
      const relatedDimension = columns.find((item) => (item?.Props && item?.Props?.Dimension && (item?.Name === column?.Props?.ParentDimension)));
      if (relatedDimension?.BuilderConfig?.IsDynamicField) {
        headerName = `${relatedDimension?.BuilderConfig?.Entity} | ${column.Name}`;
      }

      if (!hide && relatedDimension?.Props?.Dimension?.Applied === DimensionStatus.NotApplied) {
        hide = true;
        isValueColumn = false;
      }
    }
    if (isMeasure(column)) {
      if (!aggFunc && !hasPivot) {
        aggFunc = 'sum';
      }
      if (hide) {
        aggFunc = null;
      }
    }
    colsDefs.push({
      // Alias for dynamic field, name for predefined field
      colId: BuilderConfig && BuilderConfig.IsDynamicField
        ? BuilderConfig.Alias
        : Name,
      field: isPivotColumn && BuilderConfig && BuilderConfig.IsDynamicField
        ? BuilderConfig.Alias
        : autodrilldownHeader,
      hide,
      lsqLinkKey: isPivotColumn && BuilderConfig && BuilderConfig.IsDynamicField
        ? BuilderConfig.Alias
        : headerName,
      rowGroup,
      sortable: isValueColumn ? false : Props && Props.Mode === ColumnModes.Sortable && !isDashboard,
      converter: Props?.Converter,
      action: Props?.Action,
      resizable: true,
      initialWidth: 200,
      unSortIcon: isValueColumn ? false : Props && Props.Mode === ColumnModes.Sortable,
      headerTooltip: `${Props.ReferTo ? BuilderConfig?.Alias : getHeaderTooltip(column, headerName, isAutodrilldown)}`,
      isMeasure: isMeasure(column),
      formatter: Props && Props.Formatter,
      suppressMovable,
      pivot: isPivotColumn,
      enableValue: isValueColumn,
      aggFunc,
      keyCreator: (param) => {
        const colDef: GridModel.IColumnConfig = param.colDef;
        // in case of dynamic field, Alias is used as key.
        if (colDef?.builderConfig?.IsDynamicField) {
          if (!colDef.pivot) {
            return param.data[colDef.builderConfig.Alias];
          }
        }
        // for referred column, using the actual column data as key instead of referred value
        // Ex: for OwnerId and Owner Name, this returns as OwnerId as key which gets rendered in UI. This again gets fixed by cellRenderer in autoGroupColumnDef
        if (rowGroup && colDef?.parentDataColumn?.length) {
          return param.data[colDef.parentDataColumn];
        }
        if (colDef.pivot && (colDef.parentDataColumn || (colDef.isMasked && allowAutodrilldown))) {
          const key = colDef.parentDataColumn ? param.data[colDef.parentDataColumn] : param.value;
          const val = colDef.isMasked ? EncryptedText : param.value;
          return `${key}${PivotColGrpHeaderSeparator}${val}`;
        }
        return param.value;
      },
      parentDataColumn,
      builderConfig: BuilderConfig || null,
      autodrilldown: Props.AllowDrillDown,
      isMasked: Props.IsMasked,
      binningType,
      measureFieldAlias: isMeasureAndApplied(column) ? BuilderConfig?.Alias || '' : '',
      IsUserDynamicField: Props?.IsUserDynamicField,
      isDrilldownColumn: isAutodrilldown,
      props: Props,
    });
  });
  return colsDefs;
};

const getRowDataFields = (
  columns: Array<ReportResponseModel.IColumn>,
  data: ObjModel.Obj,
): Array<{ Name: string; Value: any }> => {
  if (!isValidArrayAndNotEmpty(columns)) return [];
  return isValidArrayAndNotEmpty(columns) ? columns.map((c) => {
    const name = getRowDataFieldName(c);
    const value = getRowDataFieldValue(c, data);
    return { Name: name, Value: value };
  }) : [];
};

const getRowDataFieldValue = (
  column: ReportResponseModel.IColumn,
  data: ObjModel.Obj,
): any => {
  const fieldName = getRowDataFieldName(column);
  if (column.Props?.ReferTo) {
    return !data[column.Props.ReferTo] && !data[fieldName] ? DimensionNoValue : data[fieldName];
  }
  // Check if it is a dimension set the No Value for falsy Value and in case of drilldown columns bypass this logic
  if (!column.Props?.isDrilldownColumn && (column.Props?.ReferTo || column.Props?.Dimension || column.Props?.ParentDimension)) {
    return !data[fieldName] ? DimensionNoValue : data[fieldName];
  }
  return data[fieldName];
};
const getRowDataFieldName = (column: ReportResponseModel.IColumn): string => {
  if (column.BuilderConfig && column.BuilderConfig.IsDynamicField) {
    return column.BuilderConfig.Alias;
  }
  return column.Name;
};

export const getRowData = (
  columns: Array<ReportResponseModel.IColumn>, reportData: Array<ObjModel.Obj>,
): Array<ObjModel.Obj> => {
  const rowData: Array<ObjModel.Obj> = [];
  const columnsWithSubTotals = addSubTotalsColumns(columns);

  for (let i = 0; i < reportData?.length; i += 1) {
    const data = reportData[i];
    const rowFields = getRowDataFields(columnsWithSubTotals, data);
    rowData.push({
      Fields: rowFields,
    });
  }
  return rowData;
};

export const addSubTotalsColumns = (columns: IColumn[]): IColumn[] => {
  const updatedColumns = [...columns];
  const appliedRowDims = isValidArrayAndNotEmpty(columns) ? columns.filter((col) => col.Props.Dimension && col.Props.Dimension.Applied === DimensionStatus.Applied && col.Props.Dimension.DimensionMode === DimensionMode.RowGroup) : [];
  if (appliedRowDims.length > 1 && isValidArrayAndNotEmpty(columns)) {
    columns?.forEach((item) => {
      if (isMeasureAndApplied(item)) {
        updatedColumns.push({ ...item, Name: `${item.Name}${SubTotalsSeperator}` });
        if (appliedRowDims.length > 2) updatedColumns.push({ ...item, Name: `${item.Name}${GroupedSubTotalsSeperator}` });
      }
    });
  }
  return updatedColumns;
};

const getFieldValueDashboardTable = (
  column: ReportResponseModel.IColumn,
  data: ObjModel.Obj,
): any => {
  const fieldName = column.Name;
  if (column.Props?.ReferTo) {
    return !data[column.Props.ReferTo] && !data[fieldName] ? DimensionNoValue : data[fieldName];
  }
  // Check if it is a dimension set the No Value for falsy Value and in case of drilldown columns bypass this logic
  if (!column.Props?.isDrilldownColumn && (column.Props?.ReferTo || column.Props?.Dimension || column.Props?.ParentDimension)) {
    return !data[fieldName] ? DimensionNoValue : data[fieldName];
  }
  return data[fieldName];
};
const getRowFieldsDashboardTable = (
  columns: Array<ReportResponseModel.IColumn>,
  data: ObjModel.Obj,
): Array<{ Name: string; Value: any }> => {
  if (!isValidArrayAndNotEmpty(columns)) return [];
  return isValidArrayAndNotEmpty(columns) ? columns.map((col) => {
    const name = col.Name;
    const value = getFieldValueDashboardTable(col, data);
    return { Name: name, Value: value };
  }) : [];
};

export const getRowDataDashboardTable = (columns: Array<ReportResponseModel.IColumn>, reportData: Array<ObjModel.Obj>) => {
  const rowData: Array<ObjModel.Obj> = [];
  const columnsWithSubTotals = addSubTotalsColumns(columns);
  for (let i = 0; i < reportData?.length; i += 1) {
    const data = reportData[i];
    const rowFields = getRowFieldsDashboardTable(columnsWithSubTotals, data);
    rowData.push({
      Fields: rowFields,
    });
  }
  return rowData;
};

const prepareDimensionList = (columns: Array<ReportResponseModel.IColumn>): { [key: string]: boolean; } => {
  const dimensionMap: { [key: string]: boolean; } = {};
  if (isValidArrayAndNotEmpty(columns)) {
    columns?.forEach((column) => {
      const { Props, BuilderConfig } = column;
      // is dimension
      if (Props?.Dimension) {
        if (BuilderConfig?.IsDynamicField) {
          dimensionMap[BuilderConfig.Alias] = true;
        } else {
          dimensionMap[column.Name] = true;
        }
        if (column.Props?.ReferTo && column.Props?.ReferTo.length) {
          dimensionMap[column.Props?.ReferTo] = true;
        }
      }
    });
  }

  return dimensionMap;
};

export const hasPivotColumn = (columns: Array<ReportResponseModel.IColumn>): boolean => isValidArrayAndNotEmpty(columns) && columns?.some((column) => {
  const { Props } = column;
  // is dimension
  if (Props && Props.Dimension && Props.Dimension.Applied !== DimensionStatus.NotApplied) {
    if (Props.Dimension.DimensionMode === DimensionMode.ColumnGroup
      || Props.Dimension.DimensionMode === DimensionMode.Both) {
      return true;
    }
  }
  return false;
});

export const hasAppliedPivot = (dimensions: IDimension[]) => dimensions.some(
  (dim) => dim.Applied !== DimensionStatus.NotApplied && dim.DimensionMode === DimensionMode.ColumnGroup,
);

export const areVisualisationSettingsPristine = (
  appliedDimensions: Array<ReportReduxModel.IDimension>, activeDimensions: Array<ReportReduxModel.IDimension>,
  columns: ReportResponseModel.IColumn[], measures: ReportResponseModel.IColumn[],
) => areDimensionsPristine(appliedDimensions, activeDimensions) && areMeasuresPristine(columns, measures);

export const areMeasuresPristine = (columns: ReportResponseModel.IColumn[], measures: ReportResponseModel.IColumn[]) => {
  if (isEmpty(columns) || isEmpty(measures)) return true;
  const activeMeasures = columns.filter((c) => isMeasureAndApplied(c));
  const appliedMeasures = measures.filter((m) => isMeasureAndApplied((m)));
  return isEqual(activeMeasures, appliedMeasures);
};

export const areDimensionsPristine = (
  allAppliedDimensions: Array<ReportReduxModel.IDimension>, allActiveDimensions: Array<ReportReduxModel.IDimension>,
) => {
  const appliedDimensionsNonApplied:ReportReduxModel.IDimension[] = allAppliedDimensions.filter((dimension:ReportReduxModel.IDimension) => dimension?.Applied !== DimensionStatus.NotApplied);
  const activeDimensionsNonApplied:ReportReduxModel.IDimension[] = allActiveDimensions.filter((dimension:ReportReduxModel.IDimension) => dimension?.Applied !== DimensionStatus.NotApplied);

  if (appliedDimensionsNonApplied.length !== activeDimensionsNonApplied.length) {
    return false;
  }

  let pointer = 0;

  while (pointer < appliedDimensionsNonApplied.length) {
    const appliedDim = appliedDimensionsNonApplied[pointer];
    const activeDim = activeDimensionsNonApplied[pointer];

    // Check with UniqueID combination of Entity, JoinId || NameSpace, Alias
    if (getEntityKey(appliedDim) !== getEntityKey(activeDim)) {
      return false;
    }

    // dimension mode check
    if (appliedDim.DimensionMode !== activeDim.DimensionMode) {
      return false;
    }

    // for date fields binning type check
    if (appliedDim?.DimensionProp?.Props?.BinningType !== activeDim?.DimensionProp?.Props?.BinningType) {
      return false;
    }

    pointer += 1;
  }
  return true;
};

export const applyZeroAnchorsSettings = (
  appliedDimensions: Array<ReportReduxModel.IDimension>,
  activeDimensions: Array<ReportReduxModel.IDimension>,
) => {
  const hasZeroAnchors = (dimensions: ReportReduxModel.IDimension[]) => dimensions.some(
    (dimension) => dimension.Applied !== DimensionStatus.NotApplied
        && dimension.ZeroAnchor === ZeroAnchorStatus.Applied,
  );

  const hasMismatch = (dimensions1: ReportReduxModel.IDimension[], dimensions2: ReportReduxModel.IDimension[]) => {
    if (dimensions1.length !== dimensions2.length) return true;
    return dimensions1.some(
      (dimension) => !dimensions2.some((otherDimension) => areDimensionsEqual(dimension, otherDimension)),
    );
  };

  const hasZeroAnchorsApplied = (
    dimensions: ReportReduxModel.IDimension[] | undefined,
  ): dimensions is ReportReduxModel.IDimension[] => dimensions !== undefined && hasZeroAnchors(dimensions);

  if (hasZeroAnchorsApplied(appliedDimensions) || hasZeroAnchorsApplied(activeDimensions)) {
    const activeZeroAnchors = activeDimensions?.filter(
      (dimension) => dimension.Applied !== DimensionStatus.NotApplied
        && dimension.ZeroAnchor === ZeroAnchorStatus.Applied,
    ) || [];

    const appliedZeroAnchors = appliedDimensions?.filter(
      (dimension) => dimension.Applied !== DimensionStatus.NotApplied
        && dimension.ZeroAnchor === ZeroAnchorStatus.Applied,
    ) || [];

    if (hasMismatch(activeZeroAnchors, appliedZeroAnchors)) {
      return true;
    }
  }

  return false;
};

export const extractValFromStrWithSeparator = (str: string) => {
  if (typeof str === 'string' && str && str.indexOf(PivotColGrpHeaderSeparator) !== -1) {
    return str.split(PivotColGrpHeaderSeparator)[1] || '';
  }
  return str;
};

const findIndexForDimension = (drilldownField: IColumn, responseFields: IColumn[]) => {
  if (drilldownField.BuilderConfig && drilldownField.BuilderConfig.IsDynamicField) {
    return isValidArrayAndNotEmpty(responseFields) && responseFields.findIndex((dim) => drilldownField.BuilderConfig.Alias === dim.BuilderConfig.Alias);
  }
  if (!(drilldownField.BuilderConfig && drilldownField.BuilderConfig.IsDynamicField)) {
    return isValidArrayAndNotEmpty(responseFields) && responseFields.findIndex((dim) => drilldownField.Name === dim.Name);
  }
  return -1;
};

export const compareDrilldownFields = (
  drilldownFields: Array<ReportResponseModel.IColumn>, orderedFields: Array<ReportResponseModel.IColumn>,
) => {
  if (drilldownFields && !isEmpty(drilldownFields) && orderedFields && !isEmpty(orderedFields)) {
    const appliedDrilldownFields = drilldownFields?.filter((dim) => dim?.Props?.Dimension?.Applied === DimensionStatus.Applied);
    const responseFields = orderedFields?.filter((dim) => dim?.Props?.Mode !== ColumnModes.Hidden);
    if (appliedDrilldownFields.length !== responseFields.length) {
      return false;
    }
    const isValid = isValidArrayAndNotEmpty(appliedDrilldownFields) && !appliedDrilldownFields.some((field, i) => {
      const index = findIndexForDimension(field, responseFields);
      return index === -1 || index !== i;
    });

    if (!isValid) {
      return false;
    }
    if (responseFields.length === 0 || appliedDrilldownFields.length) return true;
    return false;
  }
  return false;
};

const getMappedValue = (mappedMetaField: any, colGroupingVal: any) => (mappedMetaField[colGroupingVal[0]] === '' ? DimensionNoValue : mappedMetaField[colGroupingVal[0]]);

// inspect any one of the cell - see the col-id and match the pattern for it
export const getColumnTotals = (columnTotals: any, colGroupingAlias: string, mappedMetaFields: ObjectGeneric, isColGroupingBinningType: boolean, isDashboard: boolean, isColGroupingMetaField?: boolean, parentDimension?: string) => {
  let columnTotalsRes:ObjModel.Obj;
  const mappedMetaField = isColGroupingMetaField && mappedMetaFields && mappedMetaFields[parentDimension]; // Applied Metafield
  if (isValidObjectAndNotEmpty(columnTotals)) {
    Object.entries(columnTotals).forEach((colGroupingVal) => { // Check if any no Value exists in column Totals
      let colGroupingName = colGroupingVal[0] === '' || (isColGroupingBinningType && colGroupingVal[0] === BinningZeroValue) ? DimensionNoValue : colGroupingVal[0];
      if (!isEmpty(mappedMetaField) && isColGroupingMetaField) {
        const mappedValue = getMappedValue(mappedMetaField, colGroupingVal);
        colGroupingName = `${colGroupingVal[0] || DimensionNoValue}<SIERA_INTERNAL_REPLACE>${mappedValue}`; // Using SIERA_INTERNAL_REPLACE to identify metafields
      }
      if (isValidObjectAndNotEmpty(colGroupingVal[1])) {
        Object.entries(colGroupingVal[1]).forEach((measure) => {
          const keyString = `pivot_${colGroupingAlias}_${colGroupingName}_${measure[0]}`; // Create the key to map the values of column totals when column grouping is applied
          columnTotalsRes = {
            ...columnTotalsRes,
            [keyString]: measure[1],
          };
        });
      }
    });
  }
  return columnTotalsRes;
};

export const getGrandTotals = (grandTotals: any, colGroupingAlias: string, mappedMetaFields: Obj, isColGroupingMetaField?: boolean, parentDimension?: string) => {
  let grandTotalsRes:ObjModel.Obj;
  const mappedMetaField = isColGroupingMetaField && mappedMetaFields[parentDimension];
  Object.entries(grandTotals).forEach((measure) => {
    let keyString = `pivot_${colGroupingAlias}_{Summary}_${measure[0]}`;
    if (mappedMetaField && isColGroupingMetaField) {
      keyString = `pivot_${colGroupingAlias}_{Summary}<SIERA_INTERNAL_REPLACE>{Summary}_${measure[0]}`;
    }
    grandTotalsRes = {
      ...grandTotalsRes,
      [keyString]: measure[1],
    };
  });
  return grandTotalsRes;
};

export const checkIsBinningApplied = (columns: IColumn[]) => {
  let isBinningApplied = false;
  columns.forEach((col) => {
    if (col?.Props.Dimension?.DimensionMode === DimensionMode.RowGroup && col?.Props.Dimension?.Applied !== DimensionStatus.NotApplied && col?.Props.Dimension?.DimensionProp?.DataType === 'DateTime' && col?.Props.Dimension?.DimensionProp?.Props?.BinningType) {
      isBinningApplied = true;
    }
  });
  return isBinningApplied;
};

// we are applying [No Value] when we recieve a '' or 0 from api, here reverting to its original value
export const changeDataValuesFromNovalueToOriginal = (cellData:Obj, reportData: ReportResponseModel.IReportData) => {
  const newData :Obj = {};

  if (cellData) {
    Object?.keys(cellData)?.forEach((key:string) => {
      if (cellData[key] === DimensionNoValue) {
        // for zero values we recieve null from backend where drilldown is not allowed. So check for the type where data is not null.
        const nonNullData = reportData.Raw.Data.find((value) => value[key] !== null && value[key] !== undefined);
        newData[key] = (typeof (nonNullData && nonNullData[key]) === 'string') ? '' : 0;
      } else {
        newData[key] = cellData[key];
      }
    });
  }

  return newData;
};

// for dashboard
export const getSelectedRowData = (columns: IColumn[], data: ObjModel.Obj, clickedOnRowTotal?: boolean, clickedOnColumnTotal?: boolean, clickedOnSubTotal?:boolean,
  clickedOnGroupedSubTotal?:boolean) => {
  const selectedRowData: SelectedRow[] = [];
  const appliedDimensions = isValidArrayAndNotEmpty(columns) ? columns?.filter((col) => col?.Props?.Dimension) : [];
  appliedDimensions?.forEach((col, index) => {
    let considerCol = false;
    if (clickedOnColumnTotal && col?.Props.Dimension?.Applied !== DimensionStatus.NotApplied
      && col?.Props.Dimension?.DimensionMode === DimensionMode.ColumnGroup) {
      considerCol = true;
    } else if (!clickedOnColumnTotal && clickedOnRowTotal && col?.Props.Dimension?.Applied !== DimensionStatus.NotApplied
      && col?.Props.Dimension?.DimensionMode !== DimensionMode.ColumnGroup) {
      considerCol = true;
    } else if (!clickedOnColumnTotal && !clickedOnRowTotal && col?.Props.Dimension?.Applied !== DimensionStatus.NotApplied) {
      considerCol = true;
    }
    if (clickedOnSubTotal && index > 0 && col?.Props?.Dimension?.DimensionMode === DimensionMode.RowGroup) {
      considerCol = false;
    }
    if (clickedOnGroupedSubTotal && index > 1 && col?.Props?.Dimension?.DimensionMode === DimensionMode.RowGroup) {
      considerCol = false;
    }

    if (considerCol && isDimensionApplied(col.Props?.Dimension)) {
      const name = col.BuilderConfig?.IsDynamicField ? col.BuilderConfig.Alias : col.Name;
      selectedRowData.push({
        Alias: name,
        Entity: col.BuilderConfig?.Entity || '',
        Value: data[name],
        IsMasked: col.Props?.IsMasked,
        ReferredName: col.Props?.ReferTo || '',
        ReferredValue: data[col.Props?.ReferTo] || undefined,
        BinningType: col.Props?.Dimension?.DimensionProp?.Props?.BinningType || '',
      });
    }
  });
  return selectedRowData;
};

export const getClickedRowData = (e: ObjModel.Obj) => {
  const colDef = e.colDef;
  let data = e.data;

  if (data === undefined) {
    if (!colDef.pivotValueColumn) {
      // For row grouping with no pivot; take the first row of each group
      data = e.node?.allLeafChildren?.[0]?.data;
    }

    if (e.colDef.pivotValueColumn) {
      // For pivoted columns, find underlying data that matches all the pivot values
      const pivotColumns: Column[] = e.columnApi.getPivotColumns();
      const leafChildren = e.node?.allLeafChildren || [];
      leafChildren.forEach((leaf: RowNode) => {
        let doPick = true;
        colDef.pivotKeys.forEach((value: string, j: number) => {
          const column = pivotColumns[j].getColDef() as any;
          const parentDataColumn = column?.parentDataColumn || column?.colId;
          const columnValue = leaf?.data[parentDataColumn]?.toString();
          const expectedValue = (column.parentDataColumn && value.includes(PivotColGrpHeaderSeparator))
            ? value.split(PivotColGrpHeaderSeparator)[0]
            : value;
          doPick = doPick && (columnValue === expectedValue);
        });
        if (doPick) {
          data = leaf.data;
        }
      });
    }
  }

  return data;
};

export const getAppliedMeasuresNames = (columns: IColumn[]) => {
  const measuresNames:string[] = [];
  addSubTotalsColumns(columns).forEach((col:IColumn) => {
    if (isMeasureAndApplied(col)) {
      measuresNames.push(col.Name);
    }
  });
  return measuresNames;
};

export const checkIfBinningResponseIsString = (binningType: string) => {
  let isResponseString = true;
  isResponseString = ![DateBinningFormatters.DAY, DateBinningFormatters.WEEK, DateBinningFormatters.WEEKOFTHEYEAR, DateBinningFormatters.YEAR]
    .includes(binningType as DateBinningFormatters);
  return isResponseString;
};

export const getAppliedPivotColumn = (reportColumns: IColumn[]) => {
  let appliedColGrouping:IColumn;
  reportColumns?.forEach((col: IColumn) => {
    if (col.Props.Dimension?.DimensionMode === DimensionMode.ColumnGroup && col.Props.Dimension?.Applied !== DimensionStatus.NotApplied) {
      appliedColGrouping = col;
      if (col.Props.ReferTo !== null && (col.Props.ReferTo && col.Props.ReferTo !== '')) {
        appliedColGrouping = reportColumns.find((reportCol: IColumn) => reportCol.Name === col.Props.ReferTo);
      }
    }
  });
  return appliedColGrouping;
};

export const defaultValuesDraggableItemProps = <T extends IColumn>(col: T, index: number) => {
  const key = getFieldIdentifier(col);
  return {
    key,
    draggableId: key,
    text: displayName(col),
    data: col,
    index,
    textTooltipContent: getTextTooltipContentForMeasure(col as IColumn),
    close: {
      className: PendoClassNames.RemoveField,
    },
  } as IDraggableItemProps<T>;
};

export const defaultDimensionsDraggableItemProps = <T, >(dim: IColumn | IDimension, data: T, index: number) => {
  const key = getFieldIdentifier(dim);
  return {
    key,
    draggableId: key,
    text: displayName(dim),
    data,
    index,
    textTooltipContent: getTextTooltipContentForDimension(dim),
  } as IDraggableItemProps<T>;
};

export const getBorderColor = (replaceIndex : number, newAdditionIndex:number, dimensionIndex: number) => {
  if (replaceIndex === dimensionIndex) {
    return DraggableItemBorderColor.Blue;
  }
  if (newAdditionIndex === dimensionIndex) {
    return DraggableItemBorderColor.Green;
  }

  return DraggableItemBorderColor.None;
};

export const getHeaderTooltip = (column:IColumn, headerName: string, isAutodrilldown: boolean) => {
  if (isAutodrilldown) {
    const prefix = headerName?.split(drilldownColumnDelimiter);
    const entity = prefix?.length > 0 && prefix[0]?.trim();
    const entityExists = Object.values(FieldEntitiesType).includes(entity as FieldEntitiesType);
    if (entityExists) {
      return headerName;
    }
    if (column?.BuilderConfig?.Entity && column?.BuilderConfig?.DisplayName) {
      return `${`${column?.BuilderConfig?.Entity} | ${column?.BuilderConfig.DisplayName}`}`;
    }
  }
  return headerName;
};

export const getColumnName = (column:IColumn, isAutodrilldown:boolean) => {
  const { Props, BuilderConfig } = column;
  let name = column?.Name;
  if (BuilderConfig && BuilderConfig?.IsDynamicField) {
    if (Props?.ReferTo) name = BuilderConfig?.Alias;
    else if (isAutodrilldown) name = BuilderConfig.DisplayName;
    else name = `${BuilderConfig?.Entity} | ${BuilderConfig.DisplayName}`;
  }
  return name;
};

// returns the column which is built
// @name : column name
// @Alias : column Alias
// @measure : measure information
// @Entity : entity name
// @SchemaName : Schema name for column
// @joinID : join id for column
// @formatter : formatted for column
export const buildDrilldownColumn = (
  name: string,
  Alias: string,
  measure: IColumn,
  Entity: string,
  SchemaName: string,
  joinID?: string,
  formatter?:IColumnFormatter,
): IColumn => ({
  ...measure,
  Name: name,
  BuilderConfig: {
    ...measure.BuilderConfig,
    DisplayName: name,
    Entity,
    SchemaName,
    Alias,
    JoinID: joinID,
  },
  Props: {
    ...measure.Props,
    Formatter: isValidObjectAndNotEmpty(formatter) ? formatter : measure.Props.Formatter,
    Measure: {
      ...measure.Props.Measure,
      Name: name,
      Entity,
    },
  },
});
